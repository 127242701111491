import { Component } from 'react';
import { DataGrid, GridColDef, GridRowParams, GridRowId } from '@mui/x-data-grid';
import { Grid, Typography } from '@mui/material';

import { ReduxState } from '../rdx'
import ContainerComponent from "../mui/Container";

import * as scp from '../scopes'

type KbSourcesProps = {
}

type Source = {
  source: string;
  provider: string;
  link: string;
  params: string;
  api: string;
  last?: Date
}

type KbSourcesState = {
  source: string;
  provider: string;
  link: string;
  params: string;
  api: string;
  last?: Date

  crNew: boolean;
  sources: Source[];

  selectionModel: GridRowId[]

}



export default class KbSources extends Component<KbSourcesProps, KbSourcesState> {

  constructor(props: KbSourcesProps) {
    super(props);
    this.state = {
      crNew: true,
      source: '',
      provider: '',
      link: '',
      params: '',
      api: '',
      sources: [],
      selectionModel: []
    }
  }

  rowClick = (params: GridRowParams) => {
    this.setState({
      ...this.state,
      source: params.row.source,
      provider: params.row.provider,
      link: params.row.link,
      params: params.row.params,
      api: params.row.api,
    })
  }


  async getKbSources() {
    this.setState({
      ...this.state, sources: [
        {
          source: 'kursy',
          provider: 'NBP',
          link: 'http://api.nbp.pl/',
          params: 'data,waluta,kurs',
          api: '/kb/src/nbp-eur'
        },
        {
          source: 'stopy procentowe',
          provider: 'NBP',
          link: 'https://www.nbp.pl/home.aspx?f=/dzienne/stopy_archiwum.htm',
          params: 'data,oprocentowanie',
          api: '/kb/src/nbp-ref'
        },
        {
          source: 'koniunktura PMI',
          provider: '*',
          link: 'https://www.bankier.pl/gospodarka/wskazniki-makroekonomiczne/pmi-polska-pol',
          params: 'PMI',
          api: '/kb/src/pmi'
        }

      ]
    })

  }


  componentDidMount() { this.getKbSources() }


  render() {
    if (!scp.allow([scp.SCOPE_KN_READ])) return(<ContainerComponent>Brak dostępu</ContainerComponent>)

    let rows = [];
    let i = 0
    if (this.state.sources)
      for (let o of this.state.sources) {
        i++
        rows.push(
          {
            id: i,
            source: o.source,
            provider: o.provider,
            link: o.link,
            params: o.params,
            api: o.api
          },
        )
      }
    const columns: GridColDef[] = [
      { field: 'id', headerName: 'id', width: 50 },
      { field: 'source', headerName: 'Identyfikator', width: 150 },
      { field: 'provider', headerName: 'Dostawca', width: 150 },
      { field: 'link', headerName: 'Link', width: 150 },
      { field: 'params', headerName: 'Parametry zapytania', width: 350 },
      { field: 'api', headerName: 'api', width: 250 },
    ];


    return (
      <ContainerComponent>
        <div style={{ width: '100%' }}>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Źródła danych zewnętrznych</Typography><br />
              <div style={{color : 'red'}}>Wtyczki źródeł instaluje administrator!</div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ height: 300, width: '100%' }}>
                <DataGrid rows={rows} columns={columns} checkboxSelection={true}
                  onRowClick={this.rowClick}
                  onSelectionModelChange={
                    (newSelection) => {
                      this.setState({ ...this.state, selectionModel: newSelection });
                    }
                  }
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </ContainerComponent>

    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  redux: state
});

//export default connect(mapStateToProps)(KbSources)


