import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import TitleIcon from "@mui/icons-material/Title";
import PanoramaIcon from "@mui/icons-material/Panorama";
import AutoAwesomeMotion from "@mui/icons-material/AutoAwesomeMotion";
 
import StreamIcon from "@mui/icons-material/Stream";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";

import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CalculateIcon from "@mui/icons-material/Calculate";

import RuleIcon from "@mui/icons-material/Rule";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import EventIcon from "@mui/icons-material/Event";

const MenuComponent = () => {
  const menu = [
    {
      label: "Ewidencja i szacowanie",
      url: "#",
    },
    {
      label: "Nieruchomości",
      url: "estates",
      icon: <CorporateFareIcon />,
    },
        {
      label: "Baza transakcji",
      url: "trans",
      icon: <ReceiptLongIcon />,
    },
    {
      label: "Szacowanie",
      url: "jcmodel",
      icon: <CalculateIcon />,
    },
    {},
    {
      label: "Dane testowe",
      url: "jcmodel1",
      icon: <TitleIcon />,
    },
    {},
    /**/  
   {
      label: "Sieć neuronowa",
      url: "#",
    },
    {
      label: "Definicja",
      url: "ndef",
      icon: <StreamIcon />,
    },
    {
      label: "Trenowanie",
      url: "ntrain",
      icon: <ModelTrainingIcon />,
    },
 /*   {
      label: "Predykcja",
      url: "npredict",
      icon: <OnlinePredictionIcon />,
    },*/
    {},
    {
      label: "Źródła danych",
      url: "#",
    },
    {
      label: "Zapytania",
      url: "kbclauses",
      icon: <RuleIcon />,
    },
    {
      label: "Źródła zewnętrzne",
      url: "kbsrc",
      icon: <OpenInBrowserIcon />,
    },
    /*
    {
      label: "Zdarzenia",
      url: "kbevents",
      icon: <EventIcon />,
    },
    */
 /*   {},
    {
      label: "Wizualizacja",
      url: "#",
    },
    {
      label: "Pluginy",
      url: "stat",
      icon: <ListAltIcon />,
    },
*/
{},
{
  label: "Prezentacja",
  url: "#",
},
{
  label: "Na mapie",
  http: "https://geomatyka.com.pl/api/map",
  icon: <AutoAwesomeMotion  />,
},

  ];

  return (
    <>
      <List>
        {menu.map((row, index) => {
          return !row.url ? (
            row.http ? <a href={row.http} target="_blank" style={{textDecoration:"none", color:"MenuText"}}>
              <ListItem button>
                {row.icon ? <ListItemIcon>{row.icon}</ListItemIcon> : ""}
                <ListItemText primary={row.label} />
              </ListItem>
              </a>
            :
            <Divider key={index} />
          ) : (
            <Link
              key={index}
              style={{ textDecoration: "none", color: "inherit" }}
              to={`/ai/${row.url}`}
            >
              <ListItem button>
                {row.icon ? <ListItemIcon>{row.icon}</ListItemIcon> : ""}
                <ListItemText primary={row.label} />
              </ListItem>
            </Link>
          );
        })}
      </List>
    </>
  );
};
export default MenuComponent;
