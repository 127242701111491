import { definition } from 'redux-compact';
import {NNetDefinition, Level, NNetTrain} from '../api';


export interface NNetStore {
    name : string;
    id : number;
    names:string[];
    source : string;
    sources:string[];
    test_size : number;
    levels : Level[];
    trains : NNetTrain[]
    version : number;
  }


export const reduxNNet = definition<NNetStore>()
  .setDefault(
    {
      name : '',
      id:0,
      names:[],
      sources:[],
      test_size : 0.0,
      source : '',
      levels : [],
      trains:[],
      version:1
      })
  .addReducers({
   setDefinition: (store : NNetStore, def : NNetDefinition) => {
    return      ({...store,
      name : def.name,
      id : def.id,
      test_size : def.test_size,
      source: def.source,
      levels: def.levels,
    })

   }
,
   setName : (store : NNetStore, name : string) =>
     ( {...store,  name : name} ),
   setNames : (store : NNetStore, names : string[]) => {
     let nm='';
     if (names.length>0) nm=names[0];
     return ( {...store,  names : names, name : nm} )
   }
     ,
   setSource : (store : NNetStore, source : string) =>
     ( {...store,  source : source} ),
   setSources : (store : NNetStore, sources : string[]) => {
    if (sources && sources.length>0) {
      let src=store.source
      if (! (sources.indexOf(src)>=0))
        src=sources[0]
      return ( {...store,  sources : sources.slice(), source:src} )
    }
    else
     return ( {...store,  names : sources} )
   },

   setTestSize : (store : NNetStore, test_size : number) =>
     ( {...store,  test_size : test_size} ),
   setLevels : (store : NNetStore, levels : Level[]) =>
     ( {...store,  levels : levels, version:store.version+1} ),

   setTrains : (store : NNetStore, trains : NNetTrain[]) =>
   ( {...store,  trains : trains} ),

 });

