import { Component } from 'react';
import {
  Button,
  Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Rating,
  Select, SelectChangeEvent, Typography
} from '@mui/material';
import { PWynik, Attr, NNetService } from '../api'
//import { ReduxState } from '../rdx'

import ContainerComponent from "../mui/Container";

import * as scp from '../scopes'


type NNetProps = {
}

type NNetState = {
  name: string;
  ident: string;
  names: string[];
  test_size: number;
  source: string;

  powierzchnia: number;
  cena: number;
  dochod: number;
  ocena_lokalizacja: number;
  ocena_otoczenie: number;
  ocena_stan_tech: number;
  ocena_wykonczenie: number;
  ocena_usytuowanie: number;
  ocena_zmiana: number;
  ocena_media: number;
  ocena_inne: number;

}

const chkbNewAttrs = {
  inputProps: { 'aria-label': 'Nowa definicja' }
}

export default class NPredict extends Component<NNetProps, NNetState> {

  constructor(props: NNetProps) {
    super(props);
    this.state = {
      name: 'sieć 1',
      ident: 'demo',
      names: ['sieć 1', 'sieć 2'],
      test_size: 0.20,
      source: "0",
      powierzchnia: 0,
      cena: 0,
      dochod: 0,
      ocena_lokalizacja: 1,
      ocena_otoczenie: 1,
      ocena_stan_tech: 1,
      ocena_wykonczenie: 1,
      ocena_usytuowanie: 1,
      ocena_zmiana: 1,
      ocena_media: 1,
      ocena_inne: 1,
    }
  }

  async getNNet(name: string) {
    this.setState({ ...this.state, name: name })

  }

  async getNames() {
    this.setState({ ...this.state, names: ['sieć 1', 'sieć 2'] })

  }

  async getSzacuj() {
    let attrs: Attr[];
    attrs=[]
    attrs.push(new Attr( {
      "name": "lokalizacja",
      "nvalue": this.state.ocena_lokalizacja,
      "svalue": ""}))
    attrs.push(new Attr( {
        "name": "otoczenie",
        "nvalue": this.state.ocena_otoczenie,
        "svalue": ""})
    )
    attrs.push(new Attr( {
      "name": "stan_tech",
      "nvalue": this.state.ocena_stan_tech,
      "svalue": ""})
    )
    attrs.push(new Attr( {
    "name": "wykonczenie",
    "nvalue": this.state.ocena_wykonczenie,
    "svalue": ""})
    )
    attrs.push(new Attr( {
      "name": "usytuowanie",
      "nvalue": this.state.ocena_usytuowanie,
      "svalue": ""}))
    attrs.push(new Attr( {
        "name": "zmiana",
        "nvalue": this.state.ocena_zmiana,
        "svalue": ""})
    )
    attrs.push(new Attr( {
      "name": "media",
      "nvalue": this.state.ocena_media,
      "svalue": ""})
    )
    attrs.push(new Attr( {
    "name": "inne",
    "nvalue": this.state.ocena_inne,
    "svalue": ""})
    )
    attrs.push(new Attr( {
      "name": "powierchnia",
      "nvalue": this.state.powierzchnia,
      "svalue": ""})
      )

    NNetService.postNPrediction(
      1,
      this.state.ident,
      attrs,
       {})
      .then(
        (result : PWynik) => {
          //this.setState({ obiekty : result.obiekty });       }
          alert(result.opis)
          //this.setState({...this.state,  cena : 1 });       
        }
      ).catch((err: any) => {
        console.log('Error ' + JSON.stringify(err));
        alert('Error ' + JSON.stringify(err));
      }
      )

  }


  selNet = (event: SelectChangeEvent) => {
    this.getNNet(event.target.value as string)
  }

  componentDidMount() { this.getNames() }


  render() {
    if (!scp.allow([scp.SCOPE_NNET_PREDICT])) return(<ContainerComponent>Brak dostępu</ContainerComponent>)
    return (
      <ContainerComponent>
        <div style={{ width: '100%' }}>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Szacowanie w oparciu o sieć neuronową</Typography>
            </Grid>
            <Grid item xs={4}>

              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={this.state.name}
                label="netIdent"
                onChange={this.selNet}
                fullWidth
              >
                {this.state.names.map(
                  (name: string, index: number) =>
                    <MenuItem value={name}>{name}</MenuItem>
                )}
              </Select>
            </Grid>
            <Grid item xs={8}>
            </Grid>
            <Grid item xs={12}>
            <Typography variant="h5" gutterBottom component="span">Parametry</Typography>
              <hr />
            </Grid>
            <Grid item xs={4}>
                  <InputLabel htmlFor="val_cena">Powierzchnia</InputLabel>
                  <OutlinedInput
                    id="val_cena"
                    value={this.state.powierzchnia}
                    onChange={
                      (event: React.ChangeEvent<HTMLInputElement>) => {
                        this.setState({ ...this.state, powierzchnia: parseInt(event.target.value) })
                      }
                    }
                    endAdornment={<InputAdornment position="end"> m<sup>2</sup> </InputAdornment>}
                    label="Powierzchnia"
                  />

            </Grid>
            <Grid item xs={8}>
            </Grid>
            <Grid item xs={6}>
              <Typography component="legend">Ocena lokalizacji</Typography>
              <Rating
                name="rate_ocena_lokalizacja"
                value={this.state.ocena_lokalizacja}
                onChange={
                  (event: any, newValue: any) => {
                    this.setState({ ...this.state, ocena_lokalizacja: newValue })
                  }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography component="legend">Ocena: Otoczenie nieruchomości</Typography>
              <Rating
                name="rate_ocena_otoczenie"
                value={this.state.ocena_otoczenie}
                onChange={
                  (event: any, newValue: any) => {
                    this.setState({ ...this.state, ocena_otoczenie: newValue })
                  }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography component="legend">Ocena: Stan techniczny budynku</Typography>
              <Rating
                name="rate_ocena_stan_tech"
                value={this.state.ocena_stan_tech}
                onChange={
                  (event: any, newValue: any) => {
                    this.setState({ ...this.state, ocena_stan_tech: newValue })
                  }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography component="legend">Ocena: Standard wykończenia lokalu</Typography>
              <Rating
                name="rate_ocena_wykonczenie"
                value={this.state.ocena_wykonczenie}
                onChange={
                  (event: any, newValue: any) => {
                    this.setState({ ...this.state, ocena_wykonczenie: newValue })
                  }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography component="legend">Ocena: Usytuowanie lokalu w budynku</Typography>
              <Rating
                name="rate_ocena_usytuowanie"
                value={this.state.ocena_usytuowanie}
                onChange={
                  (event: any, newValue: any) => {
                    this.setState({ ...this.state, ocena_usytuowanie: newValue })
                  }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography component="legend">Ocena: Zmiana formy użytkowania lokalu</Typography>
              <Rating
                name="rate_ocena_zmiana"
                value={this.state.ocena_zmiana}
                onChange={
                  (event: any, newValue: any) => {
                    this.setState({ ...this.state, ocena_zmiana: newValue })
                  }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography component="legend">Ocena: Dostępne media w lokalu</Typography>
              <Rating
                name="rate_ocena_media"
                value={this.state.ocena_media}
                onChange={
                  (event: any, newValue: any) => {
                    this.setState({ ...this.state, ocena_media: newValue })
                  }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography component="legend">Ocena: wpływ czynników makroekonomicznych</Typography>
              <Rating
                name="rate_ocena_inne"
                value={this.state.ocena_inne}
                onChange={
                  (event: any, newValue: any) => {
                    this.setState({ ...this.state, ocena_inne: newValue })
                  }}
              />
            </Grid>
            { (this.state.cena>0)?
            <Grid item xs={6}>
              <InputLabel htmlFor="val_cena">Szacowana wartość</InputLabel>
              <OutlinedInput
                id="val_cena"
                value={this.state.cena}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ ...this.state, cena: parseInt(event.target.value) })
                  }
                }
                endAdornment={<InputAdornment position="end"> zł </InputAdornment>}
                label="Cena"
              />
            </Grid>:
            <></>
            }
            { (this.state.dochod>0)?
            <Grid item xs={6}>
              <InputLabel htmlFor="val_cena">Szacowany dochód</InputLabel>
              <OutlinedInput
                id="val_cena"
                value={this.state.dochod}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ ...this.state, dochod: parseInt(event.target.value) })
                  }
                }
                endAdornment={<InputAdornment position="end"> zł </InputAdornment>}
                label="Dochód"
              />
            </Grid>:<></>
            }
            <Grid item xs={12}>
              <Button variant="contained" onClick={() => this.getSzacuj()}>SZACUJ</Button>
            </Grid>
          </Grid>

        </div>
      </ContainerComponent>

    );
  }
}
