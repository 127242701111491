import { Component } from 'react';
import { Button, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import { connect } from 'react-redux';
import { ReduxState, Actions, store} from '../rdx'
import { KBStore, CParam, DefClause } from '../rdx/kb'
import { KbParam, KbService, KbSubClause } from '../api';
import {rdxClauseDef} from './KbClauses';

import KbPar  from './KbPar'
  
type KbClause1Props = {
  name : string,
  category : string,
  clause_id : number,
  redux? : KBStore,
  cIx : number
}

type KbClause1State = {
  category : string;
  clause_id : number;
  name : string;
  names:string[]; 
  cparams : CParam[];


  }

class KbClause1 extends Component<KbClause1Props, KbClause1State> {

    constructor(props : KbClause1Props){
        super(props);
        this.state={
          name : '',
          clause_id : 0,
          category : props.category,
          cparams : [],
          names : [],
        }
    }

    async getKbClause(clause_id : number, name : string)
    {
      let cps : CParam[] =[];
      this.props.redux?.params.filter(
        (value: CParam, index: number, array: CParam[]) => {
          if (value.clause_id===clause_id) 
            cps.push(value)
          return value.clause_id
        }
      )
      this.setState({...this.state, 
        name : name,
        clause_id : clause_id,
        category : this.props.category,
        cparams : cps
         })
    }

    selCategory = (event: SelectChangeEvent) => {
      debugger;
      let category : string = (event.target.value as string)
      let names=[];
      if (category !== 'źródła danych') {
        if (category==='transakcje') {
          names=['zarejestrowane transakcje']
        } else if (category==='zdarzenia') {
          names=['---']
        } else if (category==='zdefiniowane') {
          names=['---']
        } else {
          names=['---']
        }
        this.setState({...this.state, category:  category, names : names, name : names[0]})  
        return
      }
         //names=['kursy', 'stopy procentowe', 'PMI']
      let name=''
      KbService.getCatClauses(category,{})
       .then(
           (result) => {
            if (result.clauses.length>0)
              name = result.clauses[0]
            store.dispatch(Actions.reduxKb.setSources(result.clauses))
            this.setState({...this.state, category:  category, names :result.clauses, name : name})  
           }
       ).catch((err: any) => {
           console.log('Error ' + JSON.stringify(err));
           alert('Error ' + JSON.stringify(err));
         }
       )
    }

    selClause = (event: SelectChangeEvent) => {
      //this.getKbClause(0, event.target.value as string )
        let newcl : string = (event.target.value as string)
        let definition : KbSubClause[] = [];
        this.props.redux?.subClauses.forEach(
          (scl: DefClause, index1: number, array1: DefClause[]) => {
            let params : KbParam[] = []
            this.props.redux?.params.forEach(
              (par: CParam, index2: number, array2: CParam[]) => {
                if (scl.clause_id===par.clause_id) {
                  params.push({
                   name: par.name,
                   unifyClause: par.unifyClause,
                   unifyParam: par.unifyParam,
                   active: par.active,
                   value: par.value
                 })
                }
              }
            )
            definition.push({
              clause_id: scl.clause_id,
              name: scl.name,
              category : scl.category,
              order : scl.order,
              params : params
            })
          }
        )
        KbService.postClauseDef('x'+this.props.cIx.toString(), 
           newcl, 
           { 
            id: this.props.redux?.clId || 0,
            name : this.props.redux?.clName || '?',
            description : this.props.redux?.clDescription || '',
            definition : definition
           }, {})
        .then(
          (result) => {
            rdxClauseDef(result)
          }
        ).catch((err: any) => {
          console.log('Error ' + JSON.stringify(err));
          alert('Error ' + JSON.stringify(err));
        }
        )
    }

    componentDidMount() { 
    }


    render() {
      if ( ((this.state.category==='źródła danych') && (this.state.name !== this.props.name)) ||
            (this.state.name==='')) {
        this.getKbClause(this.props.clause_id,this.props.name)
      }
      return(
<Grid container spacing={2}>
  <Grid item xs={4}>
      <Select
        labelId="clause1-label"
        id="clause1-select-helper"
        value={ this.state.category }
        label="Kategoria"
        onChange={ this.selCategory }
        fullWidth
        >
          { this.props.redux? this.props.redux.categories.map(
            (name : string, index : number) => 
               <MenuItem value={name} key={index}>{name}</MenuItem>
          ):[]}
        </Select><br />
        <Select
        labelId="clause2-label"
        id="clause2-select-helper"
        style={{marginTop:"0.3em",marginBottom:"0.5em"}}
        value={ this.state.name }
        label="Zapytanie"
        onChange={ this.selClause }
        fullWidth
        >
          {
/*              (this.props.redux?.sources) ?
              this.props.redux?.sources.map(
               (name : string, index : number) => 
                  <MenuItem value={name} key={index}>{name}</MenuItem>
             ):
             <MenuItem value={this.state.name} key={0}>{this.state.name}</MenuItem>
  */           
         
        
          (this.state.names && this.state.names.length>0) ?
           this.state.names.map(
            (name : string, index : number) => 
               <MenuItem value={name} key={index}>{name}</MenuItem>
          ):
          <MenuItem value={this.state.name} key={0}>{this.state.name}</MenuItem>
        
          }
        </Select><br />
        <Button
                variant="outlined" size="small"
                color="info"
                startIcon={<ArrowUpIcon />}
                onClick={(event) => {
                  alert('operacja niedostępna')
                }}
              >
                w górę 
        </Button>
        <Button
                variant="outlined" size="small"
                color="info"
                startIcon={<ArrowDownIcon />}
                onClick={(event) => {
                  alert('operacja niedostępna')
                }}
              >
                w dół 
        </Button>
        <Button
                variant="outlined" size="small"
                color="warning"
                startIcon={<DeleteIcon />}
                onClick={(event) => {
                  alert('operacja niedostępna')
                }}
              >
                usuń
        </Button>
  </Grid>
  <Grid item xs={8}>
    <Typography variant="h6">Parametry:</Typography>
    {
    this.state.cparams.map(
      (value: CParam, index: number, array: CParam[]) => {
        let i : number | undefined;
        i = this.props.redux?.params.findIndex(
          (value1: CParam, index1: number, obj: CParam[]) => {
            return ((value.clause_id==value1.clause_id) &&
                    (value.name==value1.name))
          }
        ) 
        if (typeof(i)=='number' && i>=0)
         return <KbPar pIx={i} key={index} />
        else
         return <></>
      }
    )
    }
    
  </Grid>
  <Grid item xs={12}>
    {this.props.cIx}
    <hr />
  </Grid>
</Grid>            
  
            
        );
    }
}

const mapStateToProps = (state : ReduxState) => ({
  redux : state.reduxKb
});
  
export default connect(mapStateToProps)(KbClause1)
  

