import axiosStatic, { AxiosPromise, AxiosInstance } from 'axios';

import { store } from '../rdx'

export interface IRequestOptions {
  headers?: any;
}

export let baseUrl0 = 'https://geomatyka.com.pl'

if (window.location.href.startsWith('http://localhost') || window.location.href.startsWith('http://127.0.0.1')) {
  baseUrl0 = 'http://localhost:8700' 
}

const baseUrl = baseUrl0+'/api'

interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
function axios(configs: IRequestConfig): AxiosPromise {
  return serviceOptions.axios ? serviceOptions.axios.request(configs) : axiosStatic(configs);
}

/////////////// Login
export class UserService {

  static postLogin(
    params: {
      username?: string;
      password?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LoginInfo> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      configs.url = baseUrl +  '/login';

      configs.data = JSON.stringify({user:params['username'] as string,
                                     password:params['password'] as string})
      /*
      let data = new FormData();
      if (params['username']) {
        data.append('username', params['username'] as any);
      }
      if (params['password']) {
        data.append('password', params['password'] as any);
      }
      configs.data = data;
      */
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  static postLogout(
    params: {
      username?: string;
      session?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LoginInfo> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'multipart/form-data'
      };
      let url = baseUrl + '/logout';

      configs.url = url;

      let data = null;
      data = new FormData();
      if (params['username']) {
        data.append('username', params['username'] as any);
      }

      if (params['session']) {
        data.append('session', params['session'] as any);
      }

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}


export class LoginInfo {

  message: string;
  session: string;
  token: string;
  scopes: string;
  retcode: number;

  constructor(data: undefined | any = {}) {
    this['message'] = data['message'];
    this['session'] = data['session'];
    this['token'] = data['token'];
    this['scopes'] = data['scopes'];
    this['retcode'] = data['retcode'];
  }
}

/////////////////// model / obiekty (nieruchomości, transakcje) - struktury

export class Kategorie {
  kategorie: string[];

  constructor(data: undefined | any = {}) {
    this['kategorie'] = data['kategorie'];
  }
}


export class Lista {
  /**  */
  klucz: string;

  /**  */
  strona: number;

  /**  */
  obiekty: Obiekt[];

  constructor(data: undefined | any = {}) {
    this['klucz'] = data['klucz'];
    this['strona'] = data['strona'];
    this['obiekty'] = data['obiekty'];
  }
}

export class Obiekt {
  /** id. systemowy obiektu */
  id: number;

  /** Obiekt / nieruchomość */
  obiekt: string;

  /** Lokalizacja */
  lokalizacja: string;
  przeznaczenie: string;

  /** img */
  img: string;

  /** opowierzchnia */
  powierzchnia: number;

  /** Opis */
  opis: string;

  /** Ocena lokalizacji */
  ocena_lokalizacja: number;

  /** Ocena: Otoczenie nieruchomości */
  ocena_otoczenie: number;

  /** Ocena: Stan techniczny budynku */
  ocena_stan_tech: number;

  /** Ocena: Standard wykończenia lokalu */
  ocena_wykonczenie: number;

  /** Ocena: Usytuowanie lokalu w budynku */
  ocena_usytuowanie: number;

  /** Ocena: Zmiana formy użytkowania lokalu */
  ocena_zmiana: number;

  /** Ocena: Dostępne media w lokalu */
  ocena_media: number;

  /** Ocena: wpływ czynników makroekonomicznych */
  ocena_inne: number;

  /** Cena jednostkowa */
  cena: number;

  /** Cena jednostkowa */
  cenaTransakcji: number;

  /** Dochód jednoostkowy */
  dochod: number;

  szacowanie: number;
  kiedy : string;
  grupa : string;
  kategoria : string;
  adres : string;
  geoportal : string;
  gidTrans : string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['obiekt'] = data['obiekt'];
    this['lokalizacja'] = data['lokalizacja'];
    this['przeznaczenie'] = data['przeznaczenie'];
    this['img'] = data['img'];
    this['powierzchnia'] = data['powierzchnia'];
    this['opis'] = data['opis'];
    this['ocena_lokalizacja'] = data['ocena_lokalizacja'];
    this['ocena_otoczenie'] = data['ocena_otoczenie'];
    this['ocena_stan_tech'] = data['ocena_stan_tech'];
    this['ocena_wykonczenie'] = data['ocena_wykonczenie'];
    this['ocena_usytuowanie'] = data['ocena_usytuowanie'];
    this['ocena_zmiana'] = data['ocena_zmiana'];
    this['ocena_media'] = data['ocena_media'];
    this['ocena_inne'] = data['ocena_inne'];
    this['cena'] = data['cena'];
    this['cenaTransakcji'] = data['cenaTransakcji'];
    this['dochod'] = data['dochod'];
    this['szacowanie'] = data['szacowanie'];
    this['kiedy'] = data['kiedy'];
    this['grupa'] = data['grupa'];
    this['kategoria'] = data['kategoria'];
    this['adres'] = data['adres'];
    this['geoportal'] = data['geoportal'];
    this['gidTrans'] = data['gidTrans'];
  }
}

export class ElementOpisu {
  /** id. systemowy obiektu */
  id: number;

  /** ElementOpisu nieruchomości */
  obiekt: string;

  /** Opis */
  opis: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['obiekt'] = data['obiekt'];
    this['opis'] = data['opis'];
  }
}

export class Wynik {
  /** kod powrotu */
  kod: number;

  /** Opis */
  opis: string;

  constructor(data: undefined | any = {}) {
    this['kod'] = data['kod'];
    this['opis'] = data['opis'];
  }
}

export class PWynik {
  kod: number;
  opis: string;
  wartosc : number;

  constructor(data: undefined | any = {}) {
    this['kod'] = data['kod'];
    this['opis'] = data['opis'];
    this['wartosc'] = data['wartosc'];
  }
}

export class ListaParam {
  /**  */
  klucz: string;

  /**  */
  strona: number;

  /**  */
  parametry: Parametr[];

  constructor(data: undefined | any = {}) {
    this['klucz'] = data['klucz'];
    this['strona'] = data['strona'];
    this['parametry'] = data['parametry'];
  }
}

export class Parametr {
  /** id. systemowy faktu */
  id: number;

  /** Parametr opis */
  opis: string;

  /** Definicja faktu */
  definicja: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['opis'] = data['opis'];
    this['definicja'] = data['definicja'];
  }
}

export class WszystkieAtrybuty {
  /**  */
  id: number;

  /** kod powrotu */
  kod: number;

  /** Opis */
  opis: string;

  /**  */
  atrybuty: any;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['kod'] = data['kod'];
    this['opis'] = data['opis'];
    this['atrybuty'] = data['atrybuty'];
  }
}

export class ModelWybrane {
  /**  */
  obiekt_id: number;
  wybrane: number[];

  constructor(data: undefined | any = {}) {
    this['obiekt_id'] = data['obiekt_id'];
    this['wybrane'] = data['wybrane'].slice();
  }
}

///////////////// Model / obiekty - API

export class MService {

  static SzacujMakro(id : number, nid1 : number, nid2 : number, 
                         options: IRequestOptions = {}): Promise<Wynik> {
   return new Promise((resolve, reject) => {
    const configs: IRequestConfig = { ...options, method: 'get' };
    configs.headers = {
      ...options.headers,
      'Content-Type': 'application/json'
    };
    configs.params={ id : id, nid1 : nid1, nid2 : nid2 }
    configs.data = null;
    configs.url = baseUrl +'/M/szacuj_m'  
    axios(configs).then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
   });
  }




  static getSzacuj(a5 : boolean, lokalizacja : number, otoczenie : number, stan_tech : number, wykonczenie : number, 
                   usytuowanie : number, zmiana : number, media : number, inne : number, powierzchnia : number,
                   transakcje='',
               options: IRequestOptions = {}): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url : string;
      if (a5) url = baseUrl +'/M/szacuj5'
      else url = baseUrl +'/M/szacuj';
      configs.params={
        lokalizacja : lokalizacja, otoczenie : otoczenie, stan_tech : stan_tech, 
        wykonczenie : wykonczenie, usytuowanie : usytuowanie, zmiana : zmiana, media : media, inne : inne,
        powierzchnia : powierzchnia,
        wybor:transakcje
      }
      configs.url = url;
      configs.data = null;
      
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }


static getSzacuj2(auto_oid : number, lokalizacja : number, otoczenie : number, stan_tech : number, wykonczenie : number, 
    usytuowanie : number, zmiana : number, media : number, inne : number, powierzchnia : number,
    transakcje : string,
    options: IRequestOptions = {}): Promise<Wynik> {
return new Promise((resolve, reject) => {
const configs: IRequestConfig = { ...options, method: 'get' };
configs.headers = {
...options.headers,
'Content-Type': 'application/json'
};
let url = baseUrl +'/M/szacuj2';
configs.params={
  auto: auto_oid,
lokalizacja : lokalizacja, otoczenie : otoczenie, stan_tech : stan_tech, 
wykonczenie : wykonczenie, usytuowanie : usytuowanie, zmiana : zmiana, media : media, inne : inne,
powierzchnia : powierzchnia,
transakcje:transakcje
}
configs.url = url;
configs.data = null;

axios(configs)
.then(res => {
resolve(res.data);
})
.catch(err => {
reject(err);
});
});
}



/**
   *
   */
 static getKategorie(options: IRequestOptions = {})
 : Promise<Kategorie> {
 return new Promise((resolve, reject) => {
   const configs: IRequestConfig = { ...options, method: 'get' };
   configs.headers = {
     ...options.headers,
     'Content-Type': 'application/json'
   };
   let url = baseUrl +'/M/kategorie';
   configs.params={}
   configs.url = url;
   configs.data = null;
   axios(configs)
     .then(res => {
       resolve(res.data);
     })
     .catch(err => {
       reject(err);
     });
 });
}


  /**
   *
   */
  static getObiektLista(ilosc:number=100,strona:number=1,klucz:string='', options: IRequestOptions = {})
    : Promise<Lista> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let token=store.getState().reduxLogin.token
      if (token) {
       configs.headers['Authorization']='Bearer '+token
      } 
      let url = baseUrl +'/M/browse';
      configs.params={ilosc:ilosc,strona:strona,klucz:klucz}
      configs.url = url;
      configs.data = null;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }


  /**
   *
   */
   static getTestoweLista(ilosc:number=100,strona:number=1,klucz:string='', options: IRequestOptions = {})
   : Promise<Lista> {
   return new Promise((resolve, reject) => {
     const configs: IRequestConfig = { ...options, method: 'get' };
     configs.headers = {
       ...options.headers,
       'Content-Type': 'application/json'
     };
     let url = baseUrl +'/M/testowe';
     configs.params={ilosc:ilosc,strona:strona,klucz:klucz}
     configs.url = url;
     configs.data = null; 
     axios(configs)
       .then(res => {
         resolve(res.data);
       })
       .catch(err => {
         reject(err);
       });
   });
 }
  /**
   *
   */
   static getTransLista(ilosc:number=10,strona:number=1,kategoria:string='',klucz:string='', options: IRequestOptions = {})
   : Promise<Lista> {
   return new Promise((resolve, reject) => {
     const configs: IRequestConfig = { ...options, method: 'get' };
     configs.headers = {
       ...options.headers,
       'Content-Type': 'application/json'
     };
     let token=store.getState().reduxLogin.token
     if (token) {
      configs.headers['Authorization']='Bearer '+token
     }
     let url = baseUrl +'/M/transakcje';
     configs.params={ilosc:ilosc,strona:strona,klucz:klucz,kategoria:kategoria}
     configs.url = url;
     configs.data = null;
     axios(configs)
       .then(res => {
         resolve(res.data);
       })
       .catch(err => {
         reject(err);
       });
   });
 }

/**
   *
   */
static getTransListaK(oid : number, kategorie:string[], options: IRequestOptions = {})
 : Promise<Lista> {
 return new Promise((resolve, reject) => {
   const configs: IRequestConfig = { ...options, method: 'get' };
   configs.headers = {
     ...options.headers,
     'Content-Type': 'application/json'
   };
   let token=store.getState().reduxLogin.token
   if (token) {
    configs.headers['Authorization']='Bearer '+token
   } 
   let url = baseUrl +'/M/ktransakcje';
   let katgs=kategorie.join("|"); 
   configs.params={oid:oid, kategorie:katgs}
   configs.url = url;
   configs.data = null;
   axios(configs)
     .then(res => {
       resolve(res.data);
     })
     .catch(err => {
       reject(err);
     });
 });
}

 /**
   *
*/
static getUsun(oid : number, options: IRequestOptions = {})
: Promise<Wynik> {
return new Promise((resolve, reject) => {
  const configs: IRequestConfig = { ...options, method: 'get' };
  configs.headers = {
    ...options.headers,
    'Content-Type': 'application/json'
  };
  let url = baseUrl +'/M/delete';
  configs.params={oid:oid}
  configs.url = url;
  configs.data = null;
  axios(configs)
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
});
}
/**
*
*/
static putTransZmiany(obiekt: Obiekt, ilosc:number=10,strona:number=1,klucz:string='', options: IRequestOptions = {})
    : Promise<Lista> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/M/zmiana3';
      configs.params={
        id: obiekt.id,
        opis: obiekt.opis,
        ocena_lokalizacja: obiekt.ocena_lokalizacja,
        ocena_otoczenie: obiekt.ocena_otoczenie,
        ocena_stan_tech: obiekt.ocena_stan_tech,
        ocena_wykonczenie: obiekt.ocena_wykonczenie,
        ocena_usytuowanie: obiekt.ocena_usytuowanie,
        ocena_zmiana: obiekt.ocena_zmiana,
        ocena_media: obiekt.ocena_media,
        ocena_inne: obiekt.ocena_inne,
        cena: obiekt.cena,
        cenaTrsnsakcji: obiekt.cenaTransakcji,
        dochod: obiekt.dochod,
        kategoria : obiekt.kategoria,
        ilosc:ilosc,strona:strona,klucz:klucz,}
      configs.url = url;
      configs.data = null;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
 /**
  *
  */
 static getObiekt(id : string, options: IRequestOptions = {}): Promise<Obiekt> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let token=store.getState().reduxLogin.token
      if (token) {
       configs.headers['Authorization']='Bearer '+token
      }
      configs.url = baseUrl +'/M/get?id='+id;
      configs.data = null;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  static getAtrybuty(oid : string, options: IRequestOptions = {}): Promise<Obiekt> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let token=store.getState().reduxLogin.token
      if (token) {
       configs.headers['Authorization']='Bearer '+token
      } 
      let url = baseUrl +'/D/atrybuty?id='+oid.toString();

      configs.url = url;

      let data = null;

      configs.data = data;
      alert(JSON.stringify(configs))
      axios(configs)
        .then(res => {
          alert('OK')
          resolve(res.data);
        })
        .catch(err => {
          alert(err)
          reject(err);
        });
    });
  }

  /**
   *
   */
  static dodanieObiektu(data : Obiekt, options: IRequestOptions = {}): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/M/new';

      configs.url = url;

      configs.data = JSON.stringify(data);
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static zmianaDanychObiektu(data : Obiekt, options: IRequestOptions = {}): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/M/update';

      configs.url = url;

      configs.data = JSON.stringify(data);
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  static zapiszSzacunek(id : number, szacunek : string, options: IRequestOptions = {}): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/M/update_s';

      configs.url = url;

      configs.data = JSON.stringify({id : id, szacunek : szacunek});
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }


  static zapiszOpis(id : number, opis : string, options: IRequestOptions = {}): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/M/update_op';

      configs.url = url;

      configs.data = JSON.stringify({id : id, opis : opis});
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

}

/////////////// dodatkowe
export class DService {

  static getGmlTransaction(gid : string, options: IRequestOptions = {}): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let token=store.getState().reduxLogin.token
      if (token) {
       configs.headers['Authorization']='Bearer '+token
      } 
      let url =baseUrl + '/db/gmltrans?gid='+gid;

      configs.url = url;

      let data = null;

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }


  static getElementDet(oid : string, options: IRequestOptions = {}): Promise<WszystkieAtrybuty> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url =baseUrl + '/D/atrybuty?id='+oid;

      configs.url = url;

      let data = null;

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   *
   */
  static getElementOpisuLista(options: IRequestOptions = {}): Promise<Lista> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +baseUrl +'/D/browse';

      configs.url = url;

      let data = null;

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static uploadFile(
    params: {
      id : number;
      selectedFile : File;
      description : string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      configs.url = baseUrl +'/D/file';
      const data = new FormData();
      data.append("id", params.id.toString());
      data.append("description", params.description);
      data.append("selectedFile", params.selectedFile);
      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static dodanieTekstu(
    params: {
      /**  */
      payload: ElementOpisu;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +baseUrl +'/D/text';

      configs.url = url;

      let data = { ...params['payload'] };

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}


///////////////////
export class MWybrane {

  static getWybrane(obiekt_id=1, options: IRequestOptions = {}):
    Promise<ModelWybrane> {
  return new Promise((resolve, reject) => {
        const configs: IRequestConfig = { ...options, method: 'get' };
        configs.headers = {
          ...options.headers,
          'Content-Type': 'application/json'
        };
        configs.url = baseUrl + '/M/wybrane/'+obiekt_id.toString();
        configs.params={}
        configs.data = null;
        axios(configs)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }

  static postWybrane(obiekt_id : number, wybrane : number[], options: IRequestOptions = {}): Promise<Wynik> {
      return new Promise((resolve, reject) => {
        const configs: IRequestConfig = { ...options, method: 'post' };
        configs.headers = {
          ...options.headers,
          'Content-Type': 'application/json'
        };
        configs.url = baseUrl +'/M/wybrane';
        configs.data = JSON.stringify(
          {
            obiekt_id:obiekt_id,
            wybrane:wybrane
          }
        );
        axios(configs)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  

  }

///////////////////////////// Pluginy

export class ListaPlugins {
  /**  */
  klucz: string;

  /**  */
  strona: number;

  /**  */
  plugins: Plugin[];

  constructor(data: undefined | any = {}) {
    this['klucz'] = data['klucz'];
    this['strona'] = data['strona'];
    this['plugins'] = data['plugins'];
  }
}

export class Plugin {
  id: number;
  nazwa: string;
  zrodlo: string;
  opis: string;
  typ: number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['nazwa'] = data['nazwa'];
    this['zrodlo'] = data['zrodlo'];
    this['opis'] = data['opis'];
    this['typ'] = data['typ'];
  }
}


export class PluginService {

  static getPluginLista(ilosc:number=100,strona:number=1,klucz:string='', options: IRequestOptions = {}):
    Promise<ListaPlugins> {
  return new Promise((resolve, reject) => {
      resolve(new ListaPlugins({
        klucz:'',
        strona:1,
        plugins: [
        {
          id:1,
          nazwa: 'Wtyczka 1',
          zrodlo: 'opracowanie własne',
          opis: 'dane testowe',
          typ: 'stat'
        },
        ]}
      ));
  });
 }
}

//// sieci neuronowe - struktury
export class NSources {
    code : number;
    message: string;
    sources: string[];

    constructor(data: undefined | any = {}) {
      this['code'] = data['code'];
      this['message'] = data['message'];
      this['sources'] = data['sources'];
/*      let srcs = data['sources']
      alert(typeof(srcs))
      if (typeof(srcs) == 'object') 
        this['sources'] = data['sources']
      else 
      this['sources'] = data['sources'].slice()*/

    }
}
  
export class NPredict {
  /** ID sieci */
  nID: number;

  /** nazwa sieci */
  nIdent: string;

  /**  */
  attrs: Attr[];

  constructor(data: undefined | any = {}) {
    this['nID'] = data['nID'];
    this['nIdent'] = data['nIdent'];
    this['attrs'] = data['attrs'];
  }
}

export class Attr {
  /** name */
  name: string;

  /** wartość liczbowa */
  nvalue: number;

  /** wartość znakowa */
  svalue: string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['nvalue'] = data['nvalue'];
    this['svalue'] = data['svalue'];
  }
}

export type Level = {
  name : string,
  units : number,
  inputs : number,
  activation : string,
}

export class NNetDefinition {
  id : number;
  name : string;
  test_size : number;
  source:string;
  levels: Level[];
  //selectionModel : []
  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['test_size'] = data['test_size'];
    this['source'] = data['source'];
    this['levels'] = data['levels'];
  }
}

export class NNetList {
  nets : string[];

  constructor(data: undefined | any = {}) {
    this['nets'] = data['nets'];
  }
}


export type NTResult = {
  loss: number;
  mse: number;
  img: string
}

export type NNetTrain = {
  id : number,
  nneuron_id : number,
  epochs : number,
  test_size : number,
  order : string,
  done : string,
  loss : string,
  mse : string,
  metrics : string,
  path  :  string
}

export class NNetTrains {
  kod : number;
  opis: string;
  trains: NNetTrain[];

  constructor(data: undefined | any = {}) {
    this['kod'] = data['kod'];
    this['opis'] = data['opis'];
    this['trains'] = data['trains'];
  }
}



////// sieć neuronowa - API

export class NNetService {
  
  /**
   *
   */
  static getSources(options: IRequestOptions = {}): Promise<NSources> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json',
        'accept': 'application/json'
      };
      configs.url = baseUrl +'/NN/sources';
      configs.data = null;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static getNnAttrib(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/NN/attrib';

      configs.url = url;

      let data = null;

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static postNPrediction(id : number, ident : string, attrs: Attr[], options: IRequestOptions = {}): Promise<PWynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      configs.url = baseUrl +'/NN/prediction';
      configs.data = JSON.stringify({
        nID : id, nIdent : ident, attrs : attrs
        }
      )
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static getNnReport(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/NN/report';

      configs.url = url;

      let data = null;

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
  !!!!!!!!!!!!!train!!!!!!!!!!
   */


  /**
   *
   */
   static getTrains(options: IRequestOptions = {}): Promise<NNetTrains> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json',
        'accept': 'application/json'
      };
      configs.url = baseUrl +'/NN/train.list';
      configs.data = null;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  static postNewTrain(ident : string, epochs : number,
    options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      configs.params={id:0}
      configs.url = baseUrl +'/NN/train';
      configs.data = {
        'nneuron_id':6,
        'epochs':epochs
      };
      axios(configs)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  
  static getNnTrain(hlevels : number, units : number, epochs : number,
    options: IRequestOptions = {}): Promise<NTResult> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      configs.params={hlevels : hlevels, units : units, epochs : epochs}
      configs.url = baseUrl +'/NN/train';
      configs.data = null;
      axios(configs)
        .then(res => {
          //res.data
          let tres : NTResult = {
            loss: res.data.loss,
            mse: res.data.mse,
            img: res.data.img
          }
          resolve(tres);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  static getNNetLista(options: IRequestOptions = {}):
    Promise<NNetList> {
      return new Promise((resolve, reject) => {
        const configs: IRequestConfig = { ...options, method: 'get' };
        configs.headers = {
          ...options.headers,
          'Content-Type': 'application/json'
        };
        configs.url = baseUrl + '/NN/list';
        configs.data = null;
        axios(configs)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }

    static getNNet(name : string, options: IRequestOptions = {}): Promise<NNetDefinition> {
      return new Promise((resolve, reject) => {
        const configs: IRequestConfig = { ...options, method: 'get' };
        configs.headers = {
          ...options.headers,
          'Content-Type': 'application/json'
        };
        configs.url = baseUrl + '/NN/get';
        configs.params={'name':name}
        configs.data = null;
        axios(configs)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }

    static postNNet(data : NNetDefinition, options: IRequestOptions = {}): Promise<Wynik> {
        return new Promise((resolve, reject) => {
          const configs: IRequestConfig = { ...options, method: 'post' };
          configs.headers = {
            ...options.headers,
            'Content-Type': 'application/json'
          };
          configs.url = baseUrl +'/NN/update';
          configs.data = JSON.stringify(data);
          axios(configs)
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
      }
    
      
}

/////////////////////////////////////////
//// przetwarzanie wiedzy ..... - struktury

  export class ModelCatClauses {
    category: string;
    clauses: string[];
  
    constructor(data: undefined | any = {}) {
      this['category'] = data['category'];
      this['clauses'] = data['clauses'].slice();
    }
  }

  export type KbParam = {
    name: string; // nazwa parametru
    unifyClause: string; // unifikacja z parametrem z klauzuli ...
    unifyParam: string; // nazwa parametru z którym unifikacja
    active: boolean; // czy zwracany po wykonaniu klauzuli
    value: string; // nazwa parametru z którym unifikacja
  }
  export type KbSubClause = {
    clause_id: number; // identyfikator klauzuli
    name: string; // nazwa parametru
    category : string; // kategoria
    order : number; // kolejność w definicji
    params : KbParam[]
  }

  export class ModelClauseDef {
    id: number;
    name : string;
    description : string;
    definition : KbSubClause[]; 

    constructor(data: undefined | any = {}) {
      this['id'] = data['id'];
      this['name'] = data['name'];
      this['description'] = data['description'];
      this['definition'] = data['definition'].slice();
    }
  }
  
  export class ClauseDefX {
    id: number;
    name : string;
    description : string;
    definition : KbSubClause[]; 
    operation: string; // d+ (dodaj klauzule), s (zapis)
    oparam: string;

    constructor(data: undefined | any = {}) {
      this['id'] = data['id'];
      this['name'] = data['name'];
      this['description'] = data['description'];
      this['definition'] = data['definition'].slice();
      this['operation'] = data['operation'];
      this['oparam'] = data['oparam'];
    }
}

/////// przetwarzanie wiedzy - api
  
  export class KbService {

    static getCatClauses(category:string, options: IRequestOptions = {}):
      Promise<ModelCatClauses> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      configs.url = baseUrl +'/CL/cclauses/'+category
      configs.params={}
      configs.data = null;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          alert(err)
          reject(err);
        });
      });
   }

    static getClauseDef(clause:string, options: IRequestOptions = {}):
      Promise<ModelClauseDef> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      configs.url = baseUrl +'/CL/clause/'+clause.replace('+','&plus;')
      .replace('/','&sol;')
      configs.params={}
      configs.data = null;
      axios(configs)
        .then(res => {
          //alert(JSON.stringify(res))
          resolve(res.data);
        })
        .catch(err => {
          alert(err)
          reject(err);
        });
      });
    }

    static postClauseDef(operation : string, param : string, 
                         clause:ModelClauseDef, options: IRequestOptions = {}):
      Promise<ModelClauseDef> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      configs.url = baseUrl +'/CL/clause';
      configs.data = JSON.stringify({
        ...clause,
        operation : operation, 
        oparam : param 
      });
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          alert(err)
          reject(err);
        });
      });
    }

    static postQuery(clause:ModelClauseDef, options: IRequestOptions = {}):Promise<ModelClauseDef> {
      return new Promise((resolve, reject) => {
        const configs: IRequestConfig = { ...options, method: 'post' };
        configs.headers = {...options.headers, 'Content-Type': 'application/json' };
        configs.url = baseUrl +'/CL/query';
        configs.data = JSON.stringify(clause);
        axios(configs).then(res => {
          resolve(res.data);
        }).catch(err => {
          alert(err)
          reject(err);
        });
      });
    }


  }
  
////////////////////// kbEvent - nie skończone (na razie nie dostępne w menu

export class KbEventsList {
  /**  */
  klucz: string;

  /**  */
  strona: number;

  /**  */
  events: KbEvent[];

  constructor(data: undefined | any = {}) {
    this['klucz'] = data['klucz'];
    this['strona'] = data['strona'];
    this['events'] = data['events'];
  }
}

export class KbEvent {
  id: number;
  nazwa: string;
  opis: string;
  data: string;
  miejsce: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['nazwa'] = data['nazwa'];
    this['opis'] = data['opis'];
    this['data'] = data['data'];
    this['miejsce'] = data['miejsce'];
  }
}

export class Tmp2Service {

  static getKbEventLista(ilosc:number=100,strona:number=1,klucz:string='', options: IRequestOptions = {}):
    Promise<KbEventsList> {
  return new Promise((resolve, reject) => {
      resolve(new KbEventsList({
        klucz:'',
        strona:1,
        kbnets: [
        {
          id:1,
          nazwa: 'decyzja w/ miejsc parkingowych ',
          opis: 'budowa parkingu u styku ulic Długiej i Krótkiej',
        },
        ]}
      ));
  });
 }
}




////////////////////// dla lab.* - do wykorzstania w przyszłości
export class Fakt {
  /** id. systemowy faktu */
  id: number;

  /** Fakt opis */
  opis: string;

  /** Lokalizacja */
  lokalizacja: string;

  /** Definicja faktu */
  definicja: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['opis'] = data['opis'];
    this['lokalizacja'] = data['lokalizacja'];
    this['definicja'] = data['definicja'];
  }
}

export class Interface {
  /** id. systemowy interfejsu */
  id: number;

  /** Interface - opis */
  opis: string;

  /** Definicja interfejsu */
  definicja: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['opis'] = data['opis'];
    this['definicja'] = data['definicja'];
  }
}

export class Klauzula {
  /** id. systemowy faktu */
  id: number;

  /** Klauzula opis */
  opis: string;

  /** Definicja faktu */
  definicja: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['opis'] = data['opis'];
    this['definicja'] = data['definicja'];
  }
}

export class KbNets {
  /**  */
  klucz: string;

  /**  */
  strona: number;

  /**  */
  kbnets: KbNet[];

  constructor(data: undefined | any = {}) {
    this['klucz'] = data['klucz'];
    this['strona'] = data['strona'];
    this['kbnets'] = data['kbnets'];
  }
}

export class KbNet {
  id: number;
  nazwa: string;
  zapytanie: string;
  opis: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['nazwa'] = data['nazwa'];
    this['zapytanie'] = data['zapytanie'];
    this['opis'] = data['opis'];
  }
}



export class Tmp1Service {

  static getKbNetLista(ilosc:number=100,strona:number=1,klucz:string='', options: IRequestOptions = {}):
    Promise<KbNets> {
  return new Promise((resolve, reject) => {
      resolve(new KbNets({
        klucz:'',
        strona:1,
        kbnets: [
        {
          id:1,
          nazwa: 'definicja 1',
          zapytanie: 'klauzula 1',
          opis: 'dane testowe dla SN',
        },
        ]}
      ));
  });
 }
}


export class KneService {
  /**
   *
   */
  static getParametrListaParam(options: IRequestOptions = {}): Promise<ListaParam> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +baseUrl +'/KNE/browse';

      configs.url = url;

      let data = null;

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static dodanieParametru(
    params: {
      /**  */
      payload: Parametr;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +baseUrl +'/KNE/create';

      configs.url = url;

      let data = { ...params['payload'] };

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static zmianaParametru(
    params: {
      /**  */
      payload: Parametr;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +baseUrl +'/KNE/update';

      configs.url = url;

      let data = { ...params['payload'] };

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export class KnfService {
  /**
   *
   */
  static getKnfLista(options: IRequestOptions = {}): Promise<Lista> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +baseUrl +'/KNF/browse';

      configs.url = url;

      let data = null;

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static usunięcie(
    params: {
      /**  */
      payload: Fakt;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +baseUrl +'/KNF/delete';

      configs.url = url;

      let data = { ...params['payload'] };

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static dodanieFaktu(
    params: {
      /**  */
      payload: Fakt;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNF/new';

      configs.url = url;

      let data = { ...params['payload'] };

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static zmianaOpisu(
    params: {
      /**  */
      payload: Fakt;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNF/update';

      configs.url = url;

      let data = { ...params['payload'] };

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export class KniService {
  /**
   *
   */
  static getKniLista(options: IRequestOptions = {}): Promise<Lista> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNI/browse';

      configs.url = url;

      let data = null;

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static usuniecie(
    params: {
      /**  */
      payload: Interface;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNI/delete';

      configs.url = url;

      let data = { ...params['payload'] };

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static dodanieInterfejsu(
    params: {
      /**  */
      payload: Interface;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNI/new';

      configs.url = url;

      let data = { ...params['payload'] };

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static zmianaOpisu(
    params: {
      /**  */
      payload: Interface;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNI/update';

      configs.url = url;

      let data = { ...params['payload'] };

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export class KnkService {
  /**
   *
   */
  static getKnkLista(options: IRequestOptions = {}): Promise<Lista> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNK/browse';

      configs.url = url;

      let data = null;

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static usuniecie(
    params: {
      /**  */
      payload: Klauzula;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNK/delete';

      configs.url = url;

      let data = { ...params['payload'] };

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static dodanieFaktu(
    params: {
      /**  */
      payload: Klauzula;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNK/new';

      configs.url = url;

      let data = { ...params['payload'] };

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static zmianaOpisu(
    params: {
      /**  */
      payload: Klauzula;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNK/update';

      configs.url = url;

      let data = { ...params['payload'] };

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export class KnpService {
  /**
   *
   */
  static getKnpLista(options: IRequestOptions = {}): Promise<Lista> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNP/browse';

      configs.url = url;

      let data = null;

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static usuniecie(
    params: {
      /**  */
      payload: Parametr;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNP/delete';

      configs.url = url;

      let data = { ...params['payload'] };

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static dodanieParametru(
    params: {
      /**  */
      payload: Parametr;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNP/new';

      configs.url = url;

      let data = { ...params['payload'] };

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static zmianaOpisu(
    params: {
      /**  */
      payload: Parametr;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'post' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNP/update';

      configs.url = url;

      let data = { ...params['payload'] };

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export class KnrService {
  /**
   *
   */
  static getKnRun(options: IRequestOptions = {}): Promise<Wynik> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNR/run';

      configs.url = url;

      let data = null;

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  /**
   *
   */
  static getKnShow(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = { ...options, method: 'get' };
      configs.headers = {
        ...options.headers,
        'Content-Type': 'application/json'
      };
      let url = baseUrl +'/KNR/show';

      configs.url = url;

      let data = null;

      configs.data = data;
      axios(configs)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
