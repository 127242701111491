import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Checkbox, FormControlLabel, MenuItem, Select, TextField } from '@mui/material';

import { connect } from 'react-redux';
import { ReduxState, Actions, store } from '../rdx'
import { KBStore, CParam, DefClause } from '../rdx/kb'

type KbParProps = {
  redux?: KBStore;
  pIx : number; // index w redux.params
}

type KbParState = {
  cparam: CParam;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  marginRight: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  width : '18em',
  float: 'left',

}));


class KbPar extends React.Component<KbParProps, KbParState> {

  constructor(props: KbParProps) {
    super(props);
    if (! this.props.redux) {
      alert('Nie ma reduxa!');
      this.state = {
        cparam: {
        clause_id: 0,
        name: '?',
        unifyClause: '',
        unifyParam: '',
        active: false,
        value:''
        }
      }
    } else {
      if (props.pIx>this.props.redux.params.length) {
        this.state = {
          cparam: {
          clause_id: 0,
          name: 'BŁĄD',
          unifyClause: '',
          unifyParam: '',
          active: false,
          value:''
          }
        }  
      } else {
        this.state = {          
          cparam: {
          clause_id: this.props.redux.params[props.pIx].clause_id,
          name: this.props.redux.params[props.pIx].name,
          unifyClause: this.props.redux.params[props.pIx].unifyClause,
          unifyParam: this.props.redux.params[props.pIx].unifyParam,
          active: this.props.redux.params[props.pIx].active,
          value: this.props.redux.params[props.pIx].value
          }
        }
      }
    }
  }

  render() {
    const chkbNewClause = {};

    return (
          <Item>
            <span style={{ marginRight : '2em'}}><b>{this.state.cparam.name}</b></span>
            <FormControlLabel
              label="aktywny"
              control={<Checkbox {...chkbNewClause}
                checked={this.state.cparam.active }
                onChange={() => {
//                  store.dispatch(Actions.reduxKb.setParActive(oid,result))                
                  // this.setState({...this.state, newDefinition: ! this.state.newDefinition})
                }
                }
              />}
            />
            <br />
            unifikacja z:<br /> 
            <Select
        labelId="param1-select-helper-label"
        size="small"
        id="param1-select-helper"
        value={ this.state.cparam.unifyClause }
//        label="Klauzula"
        style = {{width: "15em", marginBottom:"0.2em"}}
        onChange={ ()=>{} }
        >
          { this.props.redux? this.props.redux.subClauses.map(
            (cl : DefClause, index : number) => 
               <MenuItem value={cl.name}>{cl.name}</MenuItem>
          ):[]}
        </Select><br />
        <Select
        labelId="par2-helper-label"
        id="par2-select-helper"
        size="small"
        value={ this.state.cparam.unifyParam }
//        label="Parametr"
        style = {{width: "15em"}}
        onChange={ ()=>{} }
        >
          { this.props.redux? this.props.redux.params.map(
            (p : CParam, index : number) => 
               <MenuItem value={p.name}>{p.name}</MenuItem>
          ):[]}
        </Select><br />
        <TextField
          id="NName"
          label="Ustalona Wartość"
          variant="standard"
          value={ this.state.cparam.value }
          size="small"
          onChange={
            (event: React.ChangeEvent<HTMLInputElement>) =>
            {
            //  this.setState({...this.state, name : event.target.value})  
            }              
          }
        />        

      </Item>
    );
  }

}


const mapStateToProps = (state: ReduxState) => ({
  redux: state.reduxKb
});

export default connect(mapStateToProps)(KbPar)


