import { Component, ReactNode } from 'react';
import {Lista, MService, DService } from '../api'
import { DataGrid, plPL, GridColDef, GridRowParams, GridRowId } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import ContainerComponent from "../mui/Container";
import { useNavigate } from "react-router-dom";

import { Actions, ReduxState, store } from '../rdx'
import { EModule, EstatesStore  } from '../rdx/estates'
import { Button, Grid, TextField } from '@mui/material';
import JCModel1 from './JCModel1';
import * as scp from '../scopes'



type TransactionsProps = {
  redux? : EstatesStore;
  navigate? : any;
  rowClick? : (params: GridRowParams) => void
}

type TransactionsState = {
//    Transactions : Obiekt[]
    selectionModel : GridRowId[];
    filtr : string;
    kategoria : string
}

class Transactions extends Component<TransactionsProps, TransactionsState> {

    constructor(props : TransactionsProps){
        super(props);
        this.state={
            selectionModel : [],
            filtr: '',
            kategoria : ''
        }
    }


    rowClick = (params: GridRowParams) => {  
      if (typeof(params.id)=='number') {
        store.dispatch(Actions.reduxEstates.setOid(params.id));
        if (this.props.redux && (this.props.redux?.obiekt?.id !== params.id)) {
          let sel=this.props.redux?.lista?.obiekty.find(o => o.id==params.id)
          if (sel) {
            store.dispatch(Actions.reduxEstates.setObiekt( params.id, sel))
            this.getGmlTransaction1(sel.gidTrans)
          }      
        }

      }

    }
  

    async getGmlTransaction()
    {
      if (this.props.redux?.obiekt?.gidTrans) {
        DService.getGmlTransaction(this.props.redux?.obiekt?.gidTrans,{})
         .then(
             (result ) => {  
                store.dispatch(Actions.reduxEstates.setAtrybuty(result.opis))
             }
          ).catch((err: any) => {
              console.log('Error ' + JSON.stringify(err));
              alert('Error ' + JSON.stringify(err));
            }
        )      
      }
    }

    async getGmlTransaction1(gid : string)
    {
        DService.getGmlTransaction(gid,{})
         .then(
            (result ) => {  
              store.dispatch(Actions.reduxEstates.setAtrybuty(result.opis))
            }
          ).catch((err: any) => {
              console.log('Error ' + JSON.stringify(err));
              alert('Error ' + JSON.stringify(err));
            }
        )      
    }

    async getTransactions(kategoria='',klucz='')
    {
        MService.getTransLista(500,1,kategoria,klucz,{})
         .then(
             (result ) => {  
                store.dispatch(Actions.reduxEstates.setLista(EModule.transactions, result))     
             }
          ).catch((err: any) => {
              console.log('Error ' + JSON.stringify(err));
              alert('Error ' + JSON.stringify(err));
            }
        )      
    }

    async zapisZmian()
    {
      if (this.props.redux?.obiekt) {
        MService.zmianaDanychObiektu(this.props.redux.obiekt, {}).then (
          (result ) => {  
           MService.getTransLista(500,1,this.state.kategoria, this.state.filtr,{})
            .then(
              (result ) => {  
               alert('Zapisano')
               store.dispatch(Actions.reduxEstates.setLista(EModule.transactions, result))     
              }
            ).catch((err: any) => {
               console.log('Error ' + JSON.stringify(err));
               alert('Error ' + JSON.stringify(err));
             }
            )      
          }
        ).catch((err: any) => {
          console.log('Error ' + JSON.stringify(err));
          alert('Error ' + JSON.stringify(err));
        }
    )      
  }
    }

    componentDidMount() {  
      if (store.getState().reduxLogin.login)
      this.getTransactions()  
    }


    atrybuty() {
      let lista : any[] = []
      if (!this.props.redux?.atrybuty) return lista
      try {
        let s=this.props.redux?.atrybuty
        if (typeof(s)=='string') 
          s=s.replace(/\'/g,'"')
        else
          s='{}'
        let aa=JSON.parse(s)
        Object.keys(aa).map(
          (key, index) => {
            let value = aa[key]; 
            if (typeof(value)=='string')
              lista.push(<li key={index}>{key}: <b>{ value }</b> </li>)
            else {
              if (typeof(value)=='object'){
                lista.push(<li  key={index}>{key}: </li>)
                Object.keys(value).map(
                  (key1, index1) => {
                    let value1 = value[key1];
                    let i1=index*10+index1
                    if (typeof(value1)=='string') 
                      lista.push(<li key={i1}> {key1}: <b>{ typeof(value1) }</b> </li>)
                    else {
                      if (typeof(value1)=='object') {
                        Object.keys(value1).map(
                          (key2, index2)  => { 
                            let value2 = value1[key2];
                            let i2=index*100+index1*10+index2
                            if (typeof(value2)=='string') {
                              lista.push(<li key={i2}> - { key2}: <b>{ value2 }</b> </li> )
                            }
                            else {
                              lista.push(<li key={i2}> - - <u>{ key2}</u>: </li> )


                              Object.keys(value2).map(
                                (key3, index3)  => { 
                                  let value3 = value2[key3];
                                  let i3=index*1000+index1*100+index2*10+index3
                                  if (typeof(value3)=='string')
                                    lista.push(<li key={i3}> - { key3}: <b>{ value3 }</b> </li> )
                                  else {
                                    lista.push(<li key={i3}> - - - [{ key3 }] - - - </li>)

                                    Object.keys(value3).map(
                                      (key4, index4)  => { 
                                        let value4 = value3[key4];
                                        let i4=index*10000+index1*1000+index2*100+index3*10+index4
                                        if (typeof(value4)=='string')
                                          lista.push(<li key={i4}> - - - { key4}: <b>{ value4 }</b> </li> )
                                        else {
                                          lista.push(<li key={i4}> - - - { key4 }: <b>{ typeof(value4) }</b>  </li> )
                                        }
                                       }
                                    )
      

                                  }
                                 }
                              )
      
                            }
                           }
                        )

                      }
                    }
                  }
                )
              }
            }
          }
        )
      } catch (error) {
        alert(error)
      }      
      return lista
    }


    render() {
      if (!store.getState().reduxLogin.login) 
      return(<ContainerComponent>
        <h3>Transakcje</h3>
        <div style={{color:'red'}}>Musisz być zalogowany!</div>
        </ContainerComponent>
      )      
/*        if (this.props.redux?.module !== EModule.transactions) {
           store.dispatch(Actions.reduxEstates.setLista(EModule.transactions, new Lista({klucz:'',strona:'',obiekty:[]})))     
           this.getTransactions();
        }
        */

        let rows = [];
        if (this.props.redux && this.props.redux.lista) {
          for (let o of this.props.redux.lista.obiekty)
            rows.push(
                { id: o.id, 
                  obiekt: o.obiekt,
                  kategoria: o.kategoria, 
                  lokalizacja: o.lokalizacja,
                  opis: o.opis,
                  geoportal: o.geoportal,
                  adres: o.adres,
                  cena: o.cena,
                  cenaTransakcji: o.cenaTransakcji,
                  dochod: o.dochod,
                  powierzchnia: o.powierzchnia            
                },
            )
        }
        const columns: GridColDef[] = [
                { field: 'id', headerName: 'id', width: 75 },
                { field: 'kategoria', headerName: 'kategoria', width: 100 },
                { field: 'obiekt', headerName: 'Obiekt', width: 150 },
                { field: 'lokalizacja', headerName: 'Lokalizacja', width: 250 },
                { field: 'opis', headerName: 'Opis', width: 350 },
                { field: 'cenaTransakcji', headerName: 'Cena', width: 150,  type: 'number', },
                { field: 'powierzchnia', headerName: 'm2', width: 100,  type: 'number' },
                {
                  field: "Geoportal",
                  width: 250,
                  renderCell: (cellValues) => {
                    return (
                      <a href={cellValues.row.geoportal} target="_blank">
                      {cellValues.row.geoportal}
                      </a>
                    );
                  }
                },
            ];
    

        return (
          <ContainerComponent>
            <h3>Transakcje</h3>
          <TextField
            id="f_filtr"
            size="small"
            value={this.state.filtr}
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) =>
              {
                this.setState({ ...this.state, filtr: event.target.value })    
              }              
            }             
            label="Filtr"
          />
          <TextField
            id="f_kategoria"
            size="small"
            value={this.state.kategoria}
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) =>
              {
                this.setState({ ...this.state, kategoria: event.target.value })    
              }              
            }             
            label="Kategoria"
          />
          <Button onClick={()=>this.getTransactions(this.state.kategoria,this.state.filtr)}>Odśwież</Button>
 
          <div style={{ height: 500, width: '100%' }}>
            <DataGrid rows={rows} columns={columns} checkboxSelection={false} 
              localeText={plPL.components.MuiDataGrid.defaultProps.localeText} 
               onRowClick={ this.rowClick }
               pageSize={10}
               //rowsPerPageOptions={[5, 10, 20]}
               onSelectionModelChange={(newSelection) => {
                this.setState({...this.state, selectionModel:newSelection});
            }}  />
          </div>
          <Grid container spacing={2}>
          <Grid item xs={12}>
          <h1>{ this.props.redux?.obiekt?.obiekt }</h1>
           </Grid>
           <Grid item xs={6}>
           { this.atrybuty() }
           </Grid>
           <Grid item xs={6}>
            {this.props.redux?.oid?
             <>
              <TextField
            id="fm_kategoria"
            size="small"
            value={this.props.redux?.obiekt?.kategoria || ''}
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) =>
              {
                if (this.props.redux?.obiekt) {
                  store.dispatch(Actions.reduxEstates.setObiekt(this.props.redux?.oid,
                    {...this.props.redux.obiekt, 
                    id : this.props.redux?.oid, 
                    kategoria: event.target.value}))  
                }
              }              
            }             
            label="Zmiana Kategorii"
          />
          &nbsp;&nbsp;
          <Button onClick={ ()=> {
            
            if (! scp.allow([scp.SCOPE_TRANSACTIONS_WRITE])) alert('Brak uprawnień!') 
            else this.zapisZmian()
          
          }}>Zapisz zmiany</Button>
             <JCModel1 oid={this.props.redux?.oid} powierzchnia />
             <br /><br />
             <TextField
            id="fm_opis"
            size="small"
            multiline
            maxRows={6}
            value={this.props.redux?.obiekt?.opis || ''}
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) =>
              {
                if (this.props.redux?.obiekt) {
                  store.dispatch(Actions.reduxEstates.setObiekt(this.props.redux?.oid,
                    {...this.props.redux.obiekt, 
                    id : this.props.redux?.oid, 
                    opis: event.target.value}))  
                }
              }              
            }             
            label="opis"
            style={{ height: '25em', width: '100%' }}
          />
  
            </>:<></>}
           </Grid>
          </Grid>
          </ContainerComponent>
            
        );
    }
}


const FTransactions = (props : TransactionsProps) => {
  let navigate = useNavigate();

  const  rowClick = (params: GridRowParams) => {  
     
//    navigate("/ai/details/"+params.row.id)
  }


  return (
   <Transactions {...props} /*rowClick={rowClick} */ navigate={navigate} />
  );
}

const mapStateToProps = (state : ReduxState) => ({
  redux : state.reduxEstates
});


export default connect(mapStateToProps)(FTransactions)
  
//export default  withRouter(Transactions)
