import { Component } from 'react';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import {MService } from '../api'
import { connect } from 'react-redux';
import { ReduxState, Actions, store } from '../rdx'
import { EstatesStore  } from '../rdx/estates'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


type SelCategoriesProps = {
  redux? : EstatesStore;
  onChange? : (nowe : string[])=>void
}

type SelCategoriesState = {
  kategorie : string[];
//  wybrane: string[]
}

class SelCategories extends Component<SelCategoriesProps, SelCategoriesState> {

    constructor(props : SelCategoriesProps){
        super(props);
        
        this.state={
            kategorie:[],
  //          wybrane: []
        }
    }


    async getKategorie()
    {
        MService.getKategorie()
         .then(
             (result ) => {  
               let kategorie = result.kategorie.slice()
               this.setState({...this.state, kategorie: kategorie})
             }
          ).catch((err: any) => {
              console.log('Error ' + JSON.stringify(err));
              alert('Error ' + JSON.stringify(err));
            }
        )      
    }

    componentDidMount() {  this.getKategorie()  }

    handleChange = (event: SelectChangeEvent<string[]>) => { // <typeof this.state.wybrane
      const {
        target: { value },
      } = event;
      let w = (typeof value === 'string') ? value.split(',') : value
      store.dispatch(Actions.reduxEstates.setWybraneKategorie(w))                
      // this.setState( {...this.state, wybrane:w} );
      if (this.props.onChange) this.props.onChange(w);
    };
  
    render() {

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="kategorie-label">Kategorie</InputLabel>
        <Select
          labelId="kategorie-label"
          id="multiple-kategorie"
          multiple
          value={
            this.props.redux?.wybrane_kategorie
            //this.state.wybrane
          }
          onChange={this.handleChange}
          input={<OutlinedInput id="select-multiple-kategorie" label="Kategorie" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {this.state.kategorie.map((name) => (
            <MenuItem
              key={name}
              value={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
            
        );
    }
}


const mapStateToProps = (state : ReduxState) => ({
  redux : state.reduxEstates
});


export default connect(mapStateToProps)(SelCategories)
  
