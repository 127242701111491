import { definition } from 'redux-compact';
import { EnumType } from 'typescript';
import { 
  Obiekt, // obiekt / nieruchomosc
  Lista, // lista obiektów
  //ElementOpisu, Fakt,Interface,Klauzula,
 } from '../api'

export enum EModule{
  none,
  transactions,
  estates
}
export interface EstatesStore {
  module : EModule;
  version: string;
  lista? :Lista;
  obiekt?: Obiekt;
  atrybuty?: string;
  wybrane : number[];
  wybrane_kategorie: string[];
  oid : number
};

export const reduxEstates = definition<EstatesStore>()
  .setDefault(
    {
      module:EModule.none,
      wybrane : [],
      wybrane_kategorie:[],
      version:'0.0.1',
      oid:0
    })
  .addReducers({
   setLista : (store : EstatesStore, mod : EModule, lista : Lista) => {
       return ( {...store,  module : mod, oid: 0, lista : lista } )
    },
   setObiekt : (store : EstatesStore, oid : number, obiekt : Obiekt) => {
       return ({...store,  obiekt : obiekt, oid: oid} )
    },
   setAtrybuty : (store : EstatesStore, atrybuty : string) => {
     return( {...store,  atrybuty : atrybuty} )
   },
   setOid : (store : EstatesStore, oid : number) => {
    return( {...store,  oid : oid} )
   },
   addSelected : (store : EstatesStore, oid : number) => {
     let wybrane : number[] = store.wybrane.slice();
     if (wybrane.indexOf(oid)>=0) return store;
     wybrane.push(oid)
     return( {...store,  wybrane : wybrane} )
   },
   clrSelected : (store : EstatesStore) => {
    return( {...store,  wybrane : []} )
   },
   delSelected : (store : EstatesStore, oid : number) => {
    let wybrane : number[] =store.wybrane.slice();
    let i=wybrane.indexOf(oid)
    if (i>=0) wybrane.splice(i, 1);
    return( {...store,  wybrane : wybrane} )
   },
   setWybrane : (store : EstatesStore, wybrane : number[]) => {
    let w : number[] = wybrane.slice()
    return( {...store,  wybrane : w} )
  },
   setWybraneKategorie : (store : EstatesStore, wybrane : string[]) => {
     let w : string[] = wybrane.slice()
     return( {...store,  wybrane_kategorie : w} )
   },

  
  });

