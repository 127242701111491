import { ChangeEventHandler, Component } from 'react';
import {Obiekt, MService } from '../api'
import { DataGrid, GridColDef, GridRowParams, GridRowId } from '@mui/x-data-grid';
import { Button, Grid, InputAdornment, InputLabel, OutlinedInput, Rating, TextField, Typography } from '@mui/material';

import { connect } from 'react-redux';
import { ReduxState, Actions, store } from '../rdx'
import ContainerComponent from "../mui/Container";
import { EstatesStore } from '../rdx/estates';
import NumberFormat from 'react-number-format'

const parseAmount = (value : string) => {
  value=value.replace(' ','')
  value=value.replace('zł','')
  return  parseFloat(value)
}

type JCModel1Props = {
  oid : number,
  powierzchnia? : boolean,
  redux? :EstatesStore
}
type JCModelState = {
    obiekty : Obiekt[]
    selectionModel : GridRowId[];
    obiekt: string;
    opis : string;
    powierzchnia : number;
    cena : number;
    dochod : number;
    ocena_lokalizacja : number;
    ocena_otoczenie: number;
    ocena_stan_tech: number;
    ocena_wykonczenie: number;
    ocena_usytuowanie: number;
    ocena_zmiana: number;
    ocena_media: number;
    ocena_inne: number;
  }

class JCModel1 extends Component<JCModel1Props, JCModelState> {

    constructor(props : JCModel1Props){
        super(props);
        this.state={
            obiekty:[],
            selectionModel : [],
            obiekt: '',
            opis : '',
            powierzchnia : 0,
            cena : 0,
            dochod : 0,
            ocena_lokalizacja:1,
            ocena_otoczenie: 1,
            ocena_stan_tech: 1,
            ocena_wykonczenie: 1,
            ocena_usytuowanie: 1,
            ocena_zmiana: 1,
            ocena_media: 1,
            ocena_inne: 1,
          }
    }


    async getJCModel()
    {
        MService.getObiektLista(100,1,'',{})
         .then(
             (result ) => {  
                this.setState({ obiekty : result.obiekty });       }
          ).catch((err: any) => {
              console.log('Error ' + JSON.stringify(err));
              alert('Error ' + JSON.stringify(err));
            }
        )      
    }

//    componentDidMount() {  this.getJCModel()  }

    rowClick = (params: GridRowParams) => {  
        //let id=params.row.id;
    }
    

    render() {
        let rows = [];
        if (this.state.obiekty)
          for (let o of this.state.obiekty)
            rows.push(
                { id: o.id, 
                  obiekt: o.obiekt, 
                  lokalizacja: o.lokalizacja,
                  opis: o.opis,
                  cena: o.cena,
                  dochod: o.dochod,
                  ocena_lokalizacja : o.ocena_lokalizacja
                },
            )
        const columns: GridColDef[] = [
                { field: 'id', headerName: 'id', width: 50 },
                { field: 'obiekt', headerName: 'Obiekt', width: 150 },
                { field: 'lokalizacja', headerName: 'Lokalizacja', width: 150 },
                { field: 'opis', headerName: 'Opis', width: 450 },
                { field: 'cena', headerName: 'Cena', width: 150 },
                { field: 'dochod', headerName: 'Dochod', width: 150 },
                { field: 'ocena_lokalizacja', headerName: 'Ocena L.', width: 150 },
            ];

            return (
            
<Grid container spacing={2}>
  <Grid item xs={6}>
  <Typography component="legend">Ocena lokalizacji</Typography>
  <Rating
        name="rate_ocena_lokalizacja"
        value={this.props.redux?.obiekt?.ocena_lokalizacja}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.props.oid,{...this.props.redux.obiekt, 
                                  id : this.props.oid, ocena_lokalizacja: newValue}))    
            }
              
          }}
      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Otoczenie nieruchomości</Typography>
  <Rating
        name="rate_ocena_otoczenie"
        value={this.props.redux?.obiekt?.ocena_otoczenie}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.props.oid,{...this.props.redux.obiekt, 
                                  id : this.props.oid, ocena_otoczenie: newValue}))    
            }
              
          }}
      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Stan techniczny budynku</Typography>
  <Rating
        name="rate_ocena_stan_tech"
        value={this.props.redux?.obiekt?.ocena_stan_tech}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.props.oid,{...this.props.redux.obiekt, 
                                  id : this.props.oid, ocena_stan_tech: newValue}))    
            }
              
          }}
      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Standard wykończenia lokalu</Typography>
  <Rating
        name="rate_ocena_wykonczenie"
        value={this.props.redux?.obiekt?.ocena_wykonczenie}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.props.oid,{...this.props.redux.obiekt, 
                                  id : this.props.oid, ocena_wykonczenie: newValue}))    
            }
              
          }}      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Usytuowanie lokalu w budynku</Typography>
  <Rating
        name="rate_ocena_usytuowanie"
        value={this.props.redux?.obiekt?.ocena_usytuowanie}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.props.oid,{...this.props.redux.obiekt, 
                                  id : this.props.oid, ocena_usytuowanie: newValue}))    
            }
              
          }}      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Zmiana formy użytkowania lokalu</Typography>
  <Rating
        name="rate_ocena_zmiana"
        value={this.props.redux?.obiekt?.ocena_zmiana}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.props.oid,{...this.props.redux.obiekt, 
                                  id : this.props.oid, ocena_zmiana: newValue}))    
            }
              
          }}      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Dostępne media w lokalu</Typography>
  <Rating
        name="rate_ocena_media"
        value={this.props.redux?.obiekt?.ocena_media}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.props.oid,{...this.props.redux.obiekt, 
                                  id : this.props.oid, ocena_media: newValue}))    
            }
              
          }}      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: wpływ czynników makroekonomicznych</Typography>
  <Rating
        name="rate_ocena_inne"
        value={this.props.redux?.obiekt?.ocena_inne}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.props.oid,{...this.props.redux.obiekt, 
                                  id : this.props.oid, ocena_inne: newValue}))    
            }
              
          }}      />
  </Grid>
  <Grid item xs={6}>
  <InputLabel htmlFor="val_cena">Cena Jednostkowa</InputLabel>
   <NumberFormat
            id="val_cena"
            value={this.props.redux?.obiekt?.cena}
            customInput={TextField}
            type="text"
             suffix=' zł'
            thousandSeparator=" "
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) => {
                if (this.props.redux && this.props.redux.obiekt) {
                  store.dispatch(Actions.reduxEstates.setObiekt(this.props.oid,{...this.props.redux.obiekt, 
                                      id : this.props.oid, 
                                      cena: parseAmount(event.target.value)}))    
                }
                  
              }}            
            //endAdornment={<InputAdornment position="end"> zł </InputAdornment>}
//            label="Cena"
          />

  <InputLabel htmlFor="val_cena">Cena: </InputLabel>
  <NumberFormat
        value={this.props.redux?.obiekt?.cenaTransakcji}
        name='cenaTransakcji'
        customInput={TextField}
        type="text"
        suffix=' zł'
        thousandSeparator=" "
        onChange={
          (event: React.ChangeEvent<HTMLInputElement>) => {
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.props.oid,{...this.props.redux.obiekt, 
                                  id : this.props.oid, 
                                  cenaTransakcji: parseAmount(event.target.value)}))    
            }
              
          }}     
      />
  </Grid>
  <Grid item xs={6}>
  <InputLabel htmlFor="val_cena">Dochód Jednostkowy</InputLabel>
     <NumberFormat
            id="val_cena"
            value={this.props.redux?.obiekt?.dochod}
            customInput={TextField}
            type="text"
            suffix=' zł'
            thousandSeparator=" "
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) => {
                if (this.props.redux && this.props.redux.obiekt) {
                  store.dispatch(Actions.reduxEstates.setObiekt(this.props.oid,{...this.props.redux.obiekt, 
                                      id : this.props.oid, 
                                      dochod: parseAmount(event.target.value)}))    
                }
                  
              }}            
            //endAdornment={<InputAdornment position="end"> zł </InputAdornment>}
            //label="Dochód"
            //thousandSeparator
            //isNumericString
            //type="number"
          />
          {this.props.powierzchnia? <>
            <InputLabel htmlFor="m2">Powierzchnia</InputLabel>
     <NumberFormat
            id="val_m2"
            value={this.props.redux?.obiekt?.powierzchnia}
            customInput={TextField}
            type="text"
            thousandSeparator=" "
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) => {
                if (this.props.redux && this.props.redux.obiekt) {
                  store.dispatch(Actions.reduxEstates.setObiekt(this.props.oid,{...this.props.redux.obiekt, 
                                      id : this.props.oid, 
                                      powierzchnia: parseAmount(event.target.value)}))    
                }
                  
              }}            
            //endAdornment={<InputAdornment position="end"> zł </InputAdornment>}
            //label="Dochód"
            //thousandSeparator
            //isNumericString
            //type="number"
          />
          </>:<></>}
  </Grid>

</Grid>            
  
            
        );
    }
}


const mapStateToProps = (state : ReduxState) =>  ({
  redux : state.reduxEstates
}) 


export default connect(mapStateToProps)(JCModel1)
