import { create, combine, StateOf } from 'redux-compact';
import { reduxEstates } from './estates';
import { reduxNNet } from './nnet';
import { reduxKb } from './kb';
import { reduxLogin } from './login';
import { applyMiddleware, createStore, Store } from 'redux';
import logger from 'redux-logger';

// redux-compact
// możemy zdefiniować więcej redux'ów i tu je złączyć w jeden
const appRedux = combine({
  reduxEstates, 
  reduxNNet, 
  reduxKb,
  reduxLogin
});

export const { Actions, reduce } = create(appRedux);
export type ReduxState = StateOf<typeof appRedux>;
export const store: Store<ReduxState> = createStore(  
  reduce,
  applyMiddleware(logger),
);
