import { definition } from 'redux-compact';

export interface LoginStore {
  session? : string;
  token? : string;
  login? : string;
  scopes? : string;
  scopesTxt? : string;
};

export const reduxLogin = definition<LoginStore>()
  .setDefault(
    { 
      session : undefined,
      token : undefined,
      login : '',
    })
  .addReducers({
    setLogin: (store: LoginStore, login : string) => (
      {...store, login : login }),
    setLoginX: (store: LoginStore, login : string, token : string, scopes : string, scopesTxt : string) => 
    {
      return (
      {...store, login : login, token : token, scopes : scopes, scopesTxt :  scopesTxt})
  },
    setSession: (store: LoginStore, login : string, newSession : string) => (
        {...store, login : login, session : newSession }),  
    setToken: (store: LoginStore, login : string, token : string) => (
      {...store, login : login, token : token }),
    });