import React from "react";
import {DService} from '../api'

type AParams = {
  oid : number
}
 
export default function Attaches(props : AParams) {
  const [selectedFile, setSelectedFile] = React.useState<File>();
  const [description, setDescription] = React.useState("");
  const [uploadResponse, setUploadResponse] = React.useState("");
  //let { oid } = useParams<AParams>()
  let { oid } = props

  const submitForm = (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    ;

    if (selectedFile) {
        DService.uploadFile({id:1,selectedFile : selectedFile, description: description})
        .then((response : any) => {
          //let res = JSON.stringify(response)
          setUploadResponse(`Zapisano Plik
          `);
        })
        .catch((error) => {
          setUploadResponse(`Błąd wysyłania pliku
  
          wynik - ${error}

          ${description}`);
        });  
      }
  };

  return (
    <div className="App">
      <form onSubmit={submitForm}>
        <input
          type="text"
          onChange={(e) => setDescription(e.target.value)}
          placeholder={"opis / nazwa"}
        />
        <br />
        <input type="file" onChange={
          (e : React.FormEvent<HTMLInputElement> ) => 
           { if (e) {
               let files = (e.target as HTMLInputElement).files;
               alert(files)
               if (files) setSelectedFile(files[0])
             }
           }
          }
                 />
        <br />
        <input type="submit" />
      </form>
      <hr />
      <pre>{uploadResponse}</pre>
      id={oid}
    </div>
  );
}
