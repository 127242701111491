import { Component } from 'react';
import {Obiekt, MService, MWybrane } from '../api'

import { connect } from 'react-redux';
import { ReduxState, Actions, store } from '../rdx'
import ContainerComponent from "../mui/Container";
import { EstatesStore } from '../rdx/estates';
import { Button, Grid, Rating, Typography, TextField } from '@mui/material';

import SelTrans from './SelTrans';
import { useParams } from 'react-router-dom';
 

type JCModel2Props = {
  oid? : string,
  redux? :EstatesStore
}
type JCModel2State = {
  oid : number,
  kategoria :string,
  net1 : number,
  net2 : number
}

class JCModel2a extends Component<JCModel2Props, JCModel2State> {

    constructor(props : JCModel2Props){
        super(props);
        if (props.oid)
          this.state={
            oid : parseInt(props.oid),
            kategoria: '',
            net1:8,
            net2:9
           }
        else
          this.state={
            oid : 57,
            kategoria: '',
            net1:8,
            net2:9
           }
    }


    async getEstate(oid : number = 0)
    {
      if (oid===0)
         oid=this.state.oid||0
      if (oid>0) {
        MService.getObiekt(oid.toString(),{})
         .then(
             (result : Obiekt) => { 
               store.dispatch(Actions.reduxEstates.setObiekt(oid,result))                
               if (result.kategoria !== '') {
                 
                 this.setState({...this.state,kategoria:result.kategoria})
                 //store.dispatch(Actions.reduxEstates.setWybraneKategorie(['H1','H2','H3']))
                 store.dispatch(Actions.reduxEstates.setWybraneKategorie([result.kategoria]))                 
               }
              }                
          ).catch((err: any) => {
              console.log('Error ' + JSON.stringify(err));
              store.dispatch(Actions.reduxEstates.setObiekt(0,new Obiekt({})))
              alert('Error ' + JSON.stringify(err));
            }
        )
        MWybrane.getWybrane(oid,{})         
        .then(
          (result ) => {  
            store.dispatch(Actions.reduxEstates.setWybrane(result.wybrane))
          }
       ).catch((err: any) => {
           console.log('Error ' + JSON.stringify(err));
       })
  
      }
    }


    prev() {
      if (this.state.oid>57) {
        this.getEstate(this.state.oid-1)
        this.setState({...this.state, oid: this.state.oid-1} )
      }
    }

    next() {
      if (this.state.oid<171) {
        this.getEstate(this.state.oid+1)
        this.setState({...this.state, oid: this.state.oid+1} )
      }
    }

    async getSzacuj(auto : boolean)
    {
        let o=this.props.redux?.obiekt;
        if (o) {
          let p = o.powierzchnia; //parseFloat(o.powierzchnia)
          let tr=''
          if (!auto && this.props.redux?.wybrane) {
            for (let t of this.props.redux.wybrane) {
              if (tr) tr=tr+'.';
              tr=tr+t.toString()
            }
            MWybrane.postWybrane(o.id, this.props.redux.wybrane,{})         
            .then(
              (result ) => {  }
           ).catch((err: any) => {
               console.log('Error ' + JSON.stringify(err));
           })
          }
          MService.getSzacuj2(auto?o.id:0, o.ocena_lokalizacja, o.ocena_otoczenie,
          o.ocena_stan_tech, o.ocena_wykonczenie, 
          o.ocena_usytuowanie, o.ocena_zmiana, o.ocena_media, o.ocena_inne, 
          p,
          tr
          ,{})
         .then(
             (result ) => {  
                alert('Szacunkowa wartosć całkowita: '+result.opis)
                if (this.props.redux?.obiekt)
                  MService.zapiszSzacunek(this.props.redux.obiekt.id,result.opis,{}).then(
                  (result ) => { 
                    // kategorie. ....
                    this.getEstate();
                    //if (auto) store.dispatch(Actions.reduxEstates.setWybraneKategorie(['H1','H2','H3']))
                   }
                )
             }
          ).catch((err: any) => {
              console.log('Error ' + JSON.stringify(err));
              alert('Error ' + JSON.stringify(err));
            }
        )      
      }
    }


    async getSzacujM()
    {
      let o=this.props.redux?.obiekt;
      if (o) {
        MService.SzacujMakro(o.id, this.state.net1, this.state.net2, {}) 
          .then(
            (result ) => { 
              alert('Szacunkowa wartosć za m2: '+result.opis)
    //?                this.getEstate();
            }
          ).catch((err: any) => {
              console.log('Error ' + JSON.stringify(err));
          })
        }
    }

    componentDidMount() {  
      //this.getEstate()  
    }

    render() {
      if (!store.getState().reduxLogin.login){
        return(<ContainerComponent>
          <h3>Szacowanie</h3>
          <div style={{color:'red'}}>Musisz być zalogowany!</div>
          </ContainerComponent>
        )        
      }
      if (! this.props.redux) 
        return <div>Nie odczytano danych {this.state.oid}!</div>
      else if ((! this.props.redux.obiekt) || (this.state.oid != this.props.redux.oid)){
          this.getEstate(this.state.oid)
          return (
            <ContainerComponent>
            <div>Nie odczytano danych!</div>
            </ContainerComponent>)
      }
      
      return (
            
              <ContainerComponent>
              <Grid container spacing={2}>
<Grid item xs={12}>

  <SelTrans />

  <hr />

  <Button
     variant="outlined" size="small"
     color="info"
     onClick={(event) => {     
                          this.prev();
                        }}
                      >
  &lt;
  </Button>
  &nbsp;
  <b>
{
  this.props.redux?.obiekt?.id
}</b> :
{
  this.props.redux?.obiekt?.obiekt
}
&nbsp;

<Button
     variant="outlined" size="small"
     color="info"
     onClick={(event) => {     
                          this.next();
                        }}
                      >
 
&gt;
                      </Button>
  <Button
                        variant="outlined" size="small"
                        color="info"
                        onClick={(event) => {     
                          this.getSzacuj(false);
                        }}
                      >
                        szacuj
                      </Button>
                      <Button
                        variant="outlined" size="small"
                        color="info"
                        onClick={(event) => {     
                          this.getSzacuj(true);
                        }}
                      >
                        z automatycznym wyborem
                      </Button>
                      <Button
                        variant="outlined" size="small"
                        color="info"
                        onClick={(event) => {     
                          this.getSzacujM();
                        }}
                      >
                        przy pomocy sieci neuronowych
                      </Button>
                      |<TextField
                      size='small'
                id="edNet1"
                //label="id sieci 1"
                helperText="ID sieci 1"
                variant="outlined"
                style={{width:'6em'}}
                value={this.state.net1}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({...this.state, net1: parseInt(event.target.value)})
                  }
                } />
| <TextField
                      size='small'
                      id="edNet1"
                //label="id sieci 2"
                helperText="ID sieci 2"
                variant="outlined"
                style={{width:'6em'}}
                value={this.state.net2}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({...this.state, net2: parseInt(event.target.value)})
                  }
                } />
                      |

                      <hr />
  </Grid>
  <Grid container spacing={2}>
  <Grid item xs={6}>
  <Typography component="legend">Ocena lokalizacji</Typography>
  <Rating
        name="rate_ocena_lokalizacja"
        value={this.props.redux.obiekt.ocena_lokalizacja}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.state.oid,{...this.props.redux.obiekt, 
                                  id : this.state.oid, ocena_lokalizacja: newValue}))    
            }
              
          }}
      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Otoczenie nieruchomości</Typography>
  <Rating
        name="rate_ocena_otoczenie"
        value={this.props.redux?.obiekt?.ocena_otoczenie}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.state.oid,{...this.props.redux.obiekt, 
                                  id : this.state.oid, ocena_otoczenie: newValue}))    
            }
              
          }}
      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Stan techniczny budynku</Typography>
  <Rating
        name="rate_ocena_stan_tech"
        value={this.props.redux?.obiekt?.ocena_stan_tech}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.state.oid,{...this.props.redux.obiekt, 
                                  id : this.state.oid, ocena_stan_tech: newValue}))    
            }
              
          }}
      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Standard wykończenia lokalu</Typography>
  <Rating
        name="rate_ocena_wykonczenie"
        value={this.props.redux?.obiekt?.ocena_wykonczenie}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.state.oid,{...this.props.redux.obiekt, 
                                  id : this.state.oid, ocena_wykonczenie: newValue}))    
            }
              
          }}      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Usytuowanie lokalu w budynku</Typography>
  <Rating
        name="rate_ocena_usytuowanie"
        value={this.props.redux?.obiekt?.ocena_usytuowanie}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.state.oid,{...this.props.redux.obiekt, 
                                  id : this.state.oid, ocena_usytuowanie: newValue}))    
            }
              
          }}      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Zmiana formy użytkowania lokalu</Typography>
  <Rating
        name="rate_ocena_zmiana"
        value={this.props.redux?.obiekt?.ocena_zmiana}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.state.oid,{...this.props.redux.obiekt, 
                                  id : this.state.oid, ocena_zmiana: newValue}))    
            }
              
          }}      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Dostępne media w lokalu</Typography>
  <Rating
        name="rate_ocena_media"
        value={this.props.redux?.obiekt?.ocena_media}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.state.oid,{...this.props.redux.obiekt, 
                                  id : this.state.oid, ocena_media: newValue}))    
            }
              
          }}      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: wpływ czynników makroekonomicznych</Typography>
  <Rating
        name="rate_ocena_inne"
        value={this.props.redux?.obiekt?.ocena_inne}
        onChange={
          (event : any, newValue : any) => { 
            if (this.props.redux && this.props.redux.obiekt) {
              store.dispatch(Actions.reduxEstates.setObiekt(this.state.oid,{...this.props.redux.obiekt, 
                                  id : this.state.oid, ocena_inne: newValue}))    
            }
              
          }}      />
  </Grid>

  </Grid>


</Grid>            
</ContainerComponent>
  
            
        );
    }
}



function FJCModel2(props : any) {
 
 let { oid } = useParams();
  return (
   <JCModel2a {...props} oid={oid || '57'} />
  );
}

const mapStateToProps = (state : ReduxState) =>  ({
  redux : state.reduxEstates
}) 


export default connect(mapStateToProps)(FJCModel2)
