import { Component } from 'react';
import {Obiekt, MService } from '../api'
import { DataGrid, plPL, GridColDef, GridRowParams, GridRowId } from '@mui/x-data-grid';
import { Button, Checkbox, FormControlLabel, Grid, InputAdornment, InputLabel, OutlinedInput, Rating, TextField, Typography } from '@mui/material';

import ContainerComponent from "../mui/Container";

import * as scp from '../scopes'


type JCModelProps = {
}
type JCModelState = {
    obiekty : Obiekt[]
    selectionModel : GridRowId[];
    oid : number;
    obiekt: string;
    opis : string;
    powierzchnia : number;
    cena : number;
    dochod : number;
    ocena_lokalizacja : number;
    ocena_otoczenie: number;
    ocena_stan_tech: number;
    ocena_wykonczenie: number;
    ocena_usytuowanie: number;
    ocena_zmiana: number;
    ocena_media: number;
    ocena_inne: number;
    a5 : boolean;
  }

export default class JCModel extends Component<JCModelProps, JCModelState> {

    constructor(props : JCModelProps){
        super(props);
        this.state={
            obiekty:[],
            selectionModel : [],
            oid : 0,
            obiekt: '',
            opis : '',
            powierzchnia : 0,
            cena : 0,
            dochod : 0,
            ocena_lokalizacja:1,
            ocena_otoczenie: 1,
            ocena_stan_tech: 1,
            ocena_wykonczenie: 1,
            ocena_usytuowanie: 1,
            ocena_zmiana: 1,
            ocena_media: 1,
            ocena_inne: 1,
            a5 : true
          }
    }


    async getJCModel()
    {
        MService.getTestoweLista(100,1,'',{})
         .then(
             (result ) => {  
                let o = result.obiekty.slice();
                this.setState({ obiekty : result.obiekty });       }
          ).catch((err: any) => {
              console.log('Error ' + JSON.stringify(err));
              alert('Error ' + JSON.stringify(err));
            }
        )      
    }

      async getSzacujZWyborem()
      {
        let tr=''
        for (let wyb of this.state.selectionModel) {
          if (tr !== '') tr=tr+'.';
          tr=tr+wyb.toString()
        }
        MService.getSzacuj(this.state.a5,this.state.ocena_lokalizacja, this.state.ocena_otoczenie,
          this.state.ocena_stan_tech, this.state.ocena_wykonczenie, 
          this.state.ocena_usytuowanie, this.state.ocena_zmiana, this.state.ocena_media, this.state.ocena_inne, 
          this.state.powierzchnia,
          tr,
         {})
             .then(
               (result ) => {  
                  //this.setState({ obiekty : result.obiekty });       }
                  alert(result.opis)
               }
            ).catch((err: any) => {
                console.log('Error ' + JSON.stringify(err));
                alert('Error ' + JSON.stringify(err));
              }
          )      
  
      }
  
    async getSzacuj()
    {
        MService.getSzacuj(this.state.a5,this.state.ocena_lokalizacja, this.state.ocena_otoczenie,
            this.state.ocena_stan_tech, this.state.ocena_wykonczenie, 
            this.state.ocena_usytuowanie, this.state.ocena_zmiana, this.state.ocena_media, this.state.ocena_inne, 
            this.state.powierzchnia,
            '',
           {})
         .then(
             (result ) => {  
                //this.setState({ obiekty : result.obiekty });       }
                alert(result.opis)
             }
          ).catch((err: any) => {
              console.log('Error ' + JSON.stringify(err));
              alert('Error ' + JSON.stringify(err));
            }
        )      

    }

    async postNew()
    {
        let o = new Obiekt({
          id : 0,
          kategoria : 'demo',
          grupa : 0,
          obiekt : this.state.obiekt,
          lokalizacja : '',
          img : '',
          powierzchnia : this.state.powierzchnia,
          opis : this.state.opis,
          ocena_lokalizacja : this.state.ocena_lokalizacja,
          ocena_otoczenie : this.state.ocena_otoczenie,
          ocena_stan_tech : this.state.ocena_stan_tech,
          ocena_wykonczenie : this.state.ocena_wykonczenie,
          ocena_usytuowanie : this.state.ocena_usytuowanie,
          ocena_zmiana : this.state.ocena_zmiana,
          ocena_media : this.state.ocena_media,
          ocena_inne : this.state.ocena_inne,
          cena : this.state.cena,
          dochod : this.state.dochod
        });
        MService.dodanieObiektu(o,{})
         .then(
             (result ) => { 
               if (result.kod=0) 
                  alert('Zapisano')    
               else
                  alert(result.opis)
               this.getJCModel()
            }
          ).catch((err: any) => {
              console.log('Error ' + JSON.stringify(err));
              alert('Error ' + JSON.stringify(err));
            }
        )      
    }

    async postUpdate()
    {
      if (! this.state.oid) {
        alert("Nie zapisano! Wybierz najpierw wiersz, dokonaj zmian, a wtedy zapisz!");
        return 0
      } 
        let o = new Obiekt({
          id : this.state.oid,
          obiekt : this.state.obiekt,
          lokalizacja : '',
          img : '',
          powierzchnia : this.state.powierzchnia,
          opis : this.state.opis,
          ocena_lokalizacja : this.state.ocena_lokalizacja,
          ocena_otoczenie : this.state.ocena_otoczenie,
          ocena_stan_tech : this.state.ocena_stan_tech,
          ocena_wykonczenie : this.state.ocena_wykonczenie,
          ocena_usytuowanie : this.state.ocena_usytuowanie,
          ocena_zmiana : this.state.ocena_zmiana,
          ocena_media : this.state.ocena_media,
          ocena_inne : this.state.ocena_inne,
          cena : this.state.cena,
          dochod : this.state.dochod
        });
        MService.zmianaDanychObiektu(o,{})
         .then(
             (result ) => { 
               if (result.kod=0) 
                  alert('Zapisano')    
               else
                  alert(result.opis);
               this.getJCModel()
            }
          ).catch((err: any) => {
              console.log('Error ' + JSON.stringify(err));
              alert('Error ' + JSON.stringify(err));
            }
        )      
    }

    async getUsun(id: number) {
      if (id >= 0) {
        let o = this.state.obiekty.find(
          (obj) => {
            return obj.id === id;
          }
        );
        if (o) {
          MService.getUsun(id, {})
            .then(
              (result) => {
                let newList = this.state.obiekty.filter(o => o.id !== id);
                this.setState({...this.state, obiekty:newList})
                }
            ).catch((err: any) => {
              console.log('Error ' + JSON.stringify(err));
              alert('Error ' + JSON.stringify(err));
            }
            )
        }
      }
    }
  
  
    componentDidMount() {  this.getJCModel()  }

    rowClick = (params: GridRowParams) => {  
      //alert(JSON.stringify(params))
      let o  = this.state.obiekty.find( (value,index) => { 
        return value.id===params.id
      } );
      if (o) {
        
        this.setState({...this.state,
          oid : o.id,
          obiekt: o.obiekt,
          opis : o.opis,
          powierzchnia : o.powierzchnia, //parseInt(o.powierzchnia),
          cena : o.cena,
          dochod : o.dochod,
          ocena_lokalizacja:o.ocena_lokalizacja,
          ocena_otoczenie: o.ocena_otoczenie,
          ocena_stan_tech: o.ocena_stan_tech,
          ocena_wykonczenie: o.ocena_wykonczenie,
          ocena_usytuowanie: o.ocena_usytuowanie,
          ocena_zmiana: o.ocena_zmiana,
          ocena_media: o.ocena_media,
          ocena_inne: o.ocena_inne,
      })
      }
        //let id=params.row.id;
    }
    

    render() {
      if (!scp.allow([scp.SCOPE_TEST_DATA_READ])) return(<ContainerComponent>Brak dostępu</ContainerComponent>)

        let rows = [];
        if (this.state.obiekty) {
          for (let o of this.state.obiekty)
            rows.push(
                { id: o.id, 
                  obiekt: o.obiekt, 
                  lokalizacja: o.lokalizacja,
                  opis: o.opis,
                  cena: o.cena,
                  powierzchnia: o.powierzchnia,
                  ocena_lokalizacja : o.ocena_lokalizacja,
                  ocena_otoczenie : o.ocena_otoczenie,
                  ocena_stan_tech : o.ocena_stan_tech,
                  ocena_wykonczenie : o.ocena_wykonczenie,
                  ocena_usytuowanie : o.ocena_usytuowanie,
                  ocena_zmiana : o.ocena_zmiana,
                  ocena_media : o.ocena_media,
                  ocena_inne : o.ocena_inne,
                },
            ); 
        }
        const columns: GridColDef[] = [
                { field: 'id', headerName: 'id', width: 50 },
                { field: 'obiekt', headerName: 'Obiekt', width: 150 },
                { field: 'cena', headerName: 'Cena', width: 150, type: 'number' },
                { field: 'powierzchnia', headerName: 'm2', width: 100, type: 'number', },
                { field: 'ocena_lokalizacja', headerName: 'L', width: 20 },
                { field: 'ocena_otoczenie', headerName: 'O', width: 20 },
                { field: 'ocena_stan_tech', headerName: 'T', width: 20 },
                { field: 'ocena_wykonczenie', headerName: 'W', width: 20 },
                { field: 'ocena_usytuowanie', headerName: 'U', width: 20 },
                { field: 'ocena_zmiana', headerName: 'Z', width: 20 },
                { field: 'ocena_media', headerName: 'M', width: 20 },
                { field: 'ocena_inne', headerName: 'E', width: 20 },
                {
                  field: "Operacje",
                  width: 150,
                  renderCell: (cellValues) => {
                    return (
                      <>
          
                        <Button
                          variant="outlined" size="small"
                          color="warning"
                          onClick={(event) => {
                            if (! scp.allow([scp.SCOPE_TEST_DATA_DELETE])) alert('Brak uprawnień!') 
                            else this.getUsun(cellValues.row.id);
                          }}
                        >
                          usuń
                        </Button>
          
                      </>
                    );
                  }
                },          
                { field: 'opis', headerName: 'Opis', width: 550 },
          ];

            return (
          <ContainerComponent>
            
          <div style={{width: '100%' }}>
<Grid container spacing={2}>
  <Grid item xs={12}>
    <h4>Dane testowe</h4>
    <DataGrid rows={rows} columns={columns}
               checkboxSelection
               disableSelectionOnClick
               localeText={plPL.components.MuiDataGrid.defaultProps.localeText} 
               onRowClick={ this.rowClick }
               onSelectionModelChange={(newSelection) => {
                this.setState({...this.state, selectionModel:newSelection});
            }}
            style={{ height:"20em"}}/>

  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Obiekt / nieruchomość</Typography>
  <TextField
          id="edObiekt"
          //label="Nazwa obiektu"
          value={this.state.obiekt}
          onChange={
            (event: React.ChangeEvent<HTMLInputElement>) =>
            { this.setState({...this.state, obiekt: event.target.value})   }              
          }
          helperText="Krótka nazwa"
          variant="standard"
          fullWidth
        />
  </Grid>
  <Grid item xs={3}>
  <InputLabel htmlFor="val_cena">Powierzchnia</InputLabel>
          <OutlinedInput
            id="val_m2"
            value={this.state.powierzchnia}
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) =>
              {
                this.setState({...this.state, powierzchnia: parseInt(event.target.value)})  
              }              
            }
            endAdornment={<InputAdornment position="end"> m<sup>2</sup> </InputAdornment>}
            label="Powierzchnia"
          />

  </Grid>
  <Grid item xs={3}>
  <InputLabel htmlFor="val_cena">Cena jednostkowa</InputLabel>
          <OutlinedInput
            id="val_cena"
            value={this.state.cena}
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) =>
              {
                this.setState({...this.state, cena: parseInt(event.target.value)})  
              }              
            }
            endAdornment={<InputAdornment position="end"> zł </InputAdornment>}
            label="Cena"
            //thousandSeparator
            //isNumericString
          />
  </Grid>
  <Grid item xs={12}>
  <TextField
          id="edOpis"
          //label="opis"
          value={this.state.opis}
          onChange={
            (event: React.ChangeEvent<HTMLInputElement>) =>
            { this.setState({...this.state, opis: event.target.value})   }              
          }
          helperText="Opis"
          variant="standard"
          fullWidth
        />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena lokalizacji</Typography>
  <Rating
        name="rate_ocena_lokalizacja"
        value={this.state.ocena_lokalizacja}
        onChange={
          (event : any, newValue : any) => { 
            this.setState({...this.state, ocena_lokalizacja:newValue})  
          }}
      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Otoczenie nieruchomości</Typography>
  <Rating
        name="rate_ocena_otoczenie"
        value={this.state.ocena_otoczenie}
        onChange={
          (event : any, newValue : any) => { 
            this.setState({...this.state, ocena_otoczenie:newValue})  
          }}
      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Stan techniczny budynku</Typography>
  <Rating
        name="rate_ocena_stan_tech"
        value={this.state.ocena_stan_tech}
        onChange={
          (event : any, newValue : any) => { 
            this.setState({...this.state, ocena_stan_tech:newValue})  
          }}
      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Standard wykończenia lokalu</Typography>
  <Rating
        name="rate_ocena_wykonczenie"
        value={this.state.ocena_wykonczenie}
        onChange={
          (event : any, newValue : any) => { 
            this.setState({...this.state, ocena_wykonczenie:newValue})  
          }}
      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Usytuowanie lokalu w budynku</Typography>
  <Rating
        name="rate_ocena_usytuowanie"
        value={this.state.ocena_usytuowanie}
        onChange={
          (event : any, newValue : any) => { 
            this.setState({...this.state, ocena_usytuowanie:newValue})  
          }}
      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Zmiana formy użytkowania lokalu</Typography>
  <Rating
        name="rate_ocena_zmiana"
        value={this.state.ocena_zmiana}
        onChange={
          (event : any, newValue : any) => { 
            this.setState({...this.state, ocena_zmiana:newValue})  
          }}
      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: Dostępne media w lokalu</Typography>
  <Rating
        name="rate_ocena_media"
        value={this.state.ocena_media}
        onChange={
          (event : any, newValue : any) => { 
            this.setState({...this.state, ocena_media:newValue})  
          }}
      />
  </Grid>
  <Grid item xs={6}>
  <Typography component="legend">Ocena: wpływ czynników makroekonomicznych</Typography>
  <Rating
        name="rate_ocena_inne"
        value={this.state.ocena_inne}
        onChange={
          (event : any, newValue : any) => { 
            this.setState({...this.state, ocena_inne:newValue})  
          }}
      />
  </Grid>
  <Grid item xs={12}>
  <FormControlLabel
        label="Tylko 5 atrybutów"
        control={
          <Checkbox checked={this.state.a5} 
          onChange={
            (event : any, newValue : any) => { 
              this.setState({...this.state, a5:newValue})  
            }}
  />}/>
  <Button variant="contained" onClick={ ()=>this.getSzacujZWyborem() }>SZACUJ</Button> 
  { scp.allow([scp.SCOPE_TEST_DATA_WRITE])?
  <Button variant="outlined" onClick={ ()=>this.postUpdate() }>ZAPISZ ZMIANY</Button>:<></>}
  { scp.allow([scp.SCOPE_TEST_DATA_NEW])?
  <Button variant="outlined" onClick={ ()=>this.postNew() }>DODAJ JAKO NOWY</Button>:<></>}
  </Grid>
</Grid>            
  
          </div>
          </ContainerComponent>
            
        );
    }
}

/*const mapStateToProps = (state : ReduxState) => ({
    redux : state
  });
  */
//export default connect(mapStateToProps)(JCModel)
  

