import { PanoramaSharp } from '@mui/icons-material';
import { definition } from 'redux-compact';

export type CParam = {
  clause_id: number; // identyfikator klauzuli
  name: string; // nazwa parametru
  unifyClause: string; // unifikacja z parametrem z klauzuli ...
  unifyParam: string; // nazwa parametru z którym unifikacja
  active: boolean; // czy zwracany po wykonaniu klauzuli
  value:string;
}
export type DefClause = {
  clause_id: number; // identyfikator klauzuli
  name: string; // nazwa parametru
  category: string; // kategoria
  order : number; // kolejność w definicji
}

/////////// STOS - do wykorzystania w przyszłości (zob lab/kb/*)
/*
const buildInClauses = [
  { label: 'Dodawanie', id: 1, params:['suma', 'składnik1', 'składnik2']  },
  { label: 'Mnożenie', id: 2, params:['iloczyn', 'czynnik1', 'czynnik2']  },
  { label: 'Odejmowanie', id: 3, params:['różnica', 'odjemna', 'odjemnik']  },
  { label: 'Dzielenie', id: 4, params:['iloraz', 'dzielna', 'dzielnik']  },
  { label: 'Pasuje data', id: 5, params:['zgodne', 'data1', 'data2']  },
]
*/

export type CallDefR = {
  wynik: boolean,
  arg1: boolean,
  arg2: boolean,
  arg3: boolean,
}

type ParStackBind = {
  defIdent : string, // identyfikator w definicji klauzuli
  stackIdent : string // identyfikator ze stosu
  pop : boolean // czy po unifikacji zmienna (stackIdent) ma zniknąć ze stosu
}

type RPNCall = {
  name : string, // identyfikator klauzuli /funkcji / operatora
  buildIn : boolean,
  pop : ParStackBind[], // zmienne ze stosu 
  push : string[] // zmienne wkładane na stos po wykonaniu każdej iteracji
}

type RPNParam = {
  ident : string
}

type RPNValue = {
  value : string
}

type RPNElement = RPNCall | RPNParam |  RPNValue | null;

type RPNStack = RPNElement[] // stos odwortnej notacji polskiej
/////////// - koniec STOS //////////////////

export interface KBStore {

  // aktualna klauzula
  clId : number;
  clNew : boolean;
  clNet : boolean;
  clName : string;
  clDescription : string;

  // do definicji
  subClauses : DefClause[]; // definicja klauzuli
  params : CParam[]; // parametry wszystkich klauzul
  categories : string[]; // możliwe kategorie edytowanej klauzuli (subClause)
  category : string; // wybrana kategoria
  clauses : string[]; // klauzule kategorii "zdefiniowane" - do wyboru (jako subClause)
  sources : string[]; // klauzule kategorii "źródła danch"

// rpn : RPNStack;
// rpnElement : RPNElement;
//  callDef : CallDefR;
  /*
  newDefinition : boolean;
  selected : string;
  value : string;
  param : string;
  call : string;
  */
};

export const reduxKb = definition<KBStore>()
  .setDefault(
    {
      clId : 0,
      clNew : true,
      clNet : true,
      clName : '',
      clDescription : '',

      subClauses : [],
      params : [],
      categories : ['transakcje', 'źródła danych', /*'zdarzenia', */ 'zdefiniowane'],
      category : 'transakcje',
      clauses : ['zarejestrowane transakcje'],
      sources: [],
      /*
      callDef : {  wynik: false,
        arg1: false,
        arg2: false,
        arg3: false,
      },*/    
    
/*
      name : '',
      description : '',
      newDefinition : true,
      selected : '',
      value : '',
      param : '',
      call : '',
      */
        })
  .addReducers({
   
    setClName : (store : KBStore, name : string) => ( {...store,  clName : name} ),
    setClDescription : (store : KBStore, description : string) => ( {...store,  clDescription : description} ),
    setClauses : (store : KBStore, clauses : string[]) => ( {...store,  clauses : clauses} ),
    setSources : (store : KBStore, sources : string[]) => ( {...store,  sources : sources} ),

    selClause : (store : KBStore, 
      clId : number,
      clName : string,
      clDescription : string
      ) => ( {...store, clId : clId,
        clName : clName,
        clDescription : clDescription,
        clNew : false
    } ),

    setClause2 : (store : KBStore, clId: number, clName : string, clDescription : string, 
      subClauses : DefClause[], params : CParam[] ) => {
/*        alert(clId)
        alert(clName)
        alert(clDescription)
        alert(JSON.stringify(subClauses))
        alert(JSON.stringify(params))
        alert('OK?')*/
        return( { ...store, 
        clId: clId, clName : clName, clDescription : clDescription, 
        subClauses : subClauses.slice(), params: params.slice()
      })},
    
    newClause : (store : KBStore) => ( { ...store, clNew : true}),
    newSubClause: (store : KBStore) => {
      let sub=store.subClauses.slice()
      if (sub.length==0) {
        sub.push({
          clause_id: 1,
          name: 'transakcje',
          category: 'transakcje',
          order : 1
        })
      } else {
        sub.push({
          clause_id: 0,
          name: '?',
          category: 'źródła danych',
          order : sub.length
        })
      }
      return { ...store, subClauses : sub}
    },
    setNet : (store : KBStore, clNet : boolean) => ( { ...store, clNet : clNet}),

    selectSubClause: (store : KBStore, selCIx : number) => {
    //   selCIx - index wybranej klauzuli z definicji (subClauses)
    if (selCIx<store.subClauses.length) 
      return ({ 
        ...store,
        /*
      clId : store.subClauses[selCIx],
      clNew : false,
      clName : '',
      clDescription : '',
      */
      })
      else return ( {...store } )
    },
    selectParam: (store : KBStore, selPIx : number) => {
    //  selPIx - index wybranego parametru
    if (selPIx<store.params.length) 
      return ({ 
        ...store,
        /*
      clId : store.subClauses[selCIx],
      clNew : false,
      clName : '',
      clDescription : '',
      */
      })
      else return ( {...store } )
    },

//  setCallDefR : (store : KBStore, ros : CallDefR) => ( {...store,  callDef : ros } ),
    setParActive : (store : KBStore, par : string, active : boolean) => {
      // uzupełnić
       return ( {...store } )
    },
    getParList : (store : KBStore, par : string) => { 
      // uzupełnić
      return ( {...store } )
    }

 });

