import React from 'react';
import Viewer, { EventMap } from '@toast-ui/editor/dist/toastui-editor-viewer';
import { ViewerProps, EventNames } from './tui';
import {useParams} from 'react-router-dom';
import ContainerComponent from "../mui/Container";

class MyViewerComponent extends React.Component<ViewerProps> {
  rootEl = React.createRef<HTMLDivElement>();

  viewerInst!: Viewer;

  getRootElement() {
    return this.rootEl.current;
  }

  getInstance() {
    return this.viewerInst;
  }

  getBindingEventNames() {
    return Object.keys(this.props)
      .filter((key) => /^on[A-Z][a-zA-Z]+/.test(key))
      .filter((key) => this.props[key as EventNames]);
  }

  bindEventHandlers(props: ViewerProps) {
    this.getBindingEventNames().forEach((key) => {
      const eventName = key[2].toLowerCase() + key.slice(3);

      this.viewerInst.off(eventName);
      this.viewerInst.on(eventName, props[key as EventNames]!);
    });
  }

  getInitEvents() {
    return this.getBindingEventNames().reduce(
      (acc: Record<string, EventMap[keyof EventMap]>, key) => {
        const eventName = (key[2].toLowerCase() + key.slice(3)) as keyof EventMap;

        acc[eventName] = this.props[key as EventNames];

        return acc;
      },
      {}
    );
  }

  componentDidMount() {
    this.viewerInst = new Viewer({
      el: this.rootEl.current!,
      ...this.props,
      events: this.getInitEvents(),
    });
  }

  shouldComponentUpdate(nextProps: ViewerProps) {
    this.bindEventHandlers(nextProps);

    return false;
  }

  render() {
    return <ContainerComponent><div ref={this.rootEl} />oid={this.props.oid}</ContainerComponent>;
  }
}

const demo = [
  '![image](https://uicdn.toast.com/toastui/img/tui-editor-bi.png)',
  '',
  '# Awesome Editor!',
  '',
  'It has been _released as opensource in 2018_ and has ~~continually~~ evolved to **receive 10k GitHub ⭐️ Stars**.',
  '',
  '## Create Instance',
  '',
  'You can create an instance with the following code and use `getHtml()` and `getMarkdown()` of the [Editor](https://github.com/nhn/tui.editor).',
  '',
  '```js',
  'const editor = new Editor(options);',
  '```',
  '',
  '> See the table below for default options',
  '> > More API information can be found in the document',
  '',
  '| name | type | description |',
  '| --- | --- | --- |',
  '| el | `HTMLElement` | container element |',
  '',
  '## Features',
  '',
  '* CommonMark + GFM Specifications',
  '   * Live Preview',
  '   * Scroll Sync',
  '   * Auto Indent',
  '   * Syntax Highlight',
  '        1. Markdown',
  '        2. Preview',
  '',
  '## Support Wrappers',
  '',
  '> * Wrappers',
  '>    1. [x] React',
  '>    2. [x] Vue',
  '>    3. [ ] Ember',
].join('\n');


type OParams = {
  oid : string
}
 
const FViewer  = function (props: any) {
  let { oid } = useParams<OParams>()
  const [content, setContent] = React.useState<string>();
  React.useEffect(() => {
    //setContent(demo);
  }, [content]);
  return <MyViewerComponent {...props} oid={oid}   
  previewStyle="vertical" 
  initialValue={content} height="400px" />;

};

export default FViewer;
