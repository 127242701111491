import { Component, ReactElement } from 'react';
import {Plugin, ListaPlugins, PluginService } from '../api'
import { DataGrid, GridColDef, GridRowParams, GridRowId, GridEvents, GridRenderCellParams, GridCellParams } from '@mui/x-data-grid';
import { Button, Link } from '@mui/material';

import { connect } from 'react-redux';
import { ReduxState } from '../rdx'
import ContainerComponent from "../mui/Container";

import React from "react";
import { useParams, useNavigate } from "react-router-dom";
//import { withRouter } from "react-router";
 

type StatPluginsProps = {
  navigate? : any;
  rowClick? : (params: GridRowParams) => void
}

type StatPluginsState = {
    StatPlugins : Plugin[]
    selectionModel : GridRowId[];
}

class StatPlugins extends Component<StatPluginsProps, StatPluginsState> {

    constructor(props : StatPluginsProps){
        super(props);
        this.state={
            StatPlugins:[],
            selectionModel : []
        }
    }
 
    handleDetailsClick = (id :  number ) => {
      //alert(JSON.stringify( id))
      //event.stopPropagation();
    };
  
    handleCellClick = (param : GridCellParams, event : GridEvents.cellClick) => {
       
      //event.stopPropagation();
    };
    
    handleRowClick = (params: GridRowParams) => {  
    //(event : GridEvents.rowClick) => {
     // alert(JSON.stringify( params.id ))
      //event.stopPropagation();
    };
    

    rowClick = (params: GridRowParams) => {  
      if (this.props.rowClick) this.props.rowClick(params)
    }
  

    async getStatPlugins()
    {
      PluginService.getPluginLista(100,1,'',{})
         .then(
             (result : ListaPlugins ) => {  
                this.setState({...this.state, StatPlugins : result.plugins });       }
          ).catch((err: any) => {
              console.log('Error ' + JSON.stringify(err));
              alert('Error ' + JSON.stringify(err));
            }
        )      
    }

    componentDidMount() {  this.getStatPlugins()  }


    render() {
        let rows = [];
        if (this.state.StatPlugins)
          for (let o of this.state.StatPlugins)
            rows.push(
                { id: o.id, 
                  nazwa: o.nazwa,
                  zrodlo: o.zrodlo,
                  opis: o.opis,
                  typ: o.typ

                },
            )
        const columns: GridColDef[] = [
                { field: 'id', headerName: 'id', width: 50 },
                { field: 'nazwa', headerName: 'Nazwa', width: 150 },
                { field: 'zrodlo', headerName: 'Źródło', width: 150 },
                { field: 'opis', headerName: 'Opis', width: 450 },
                {
                  field: "Operacje",
                  width: 250,
                  renderCell: (cellValues) => {
                    return (
                      <>
                      <Link href={'/ai/plot'}>
                      <Button
                        variant="outlined" size="small"
                        color="info"
                      >
                        ZOBACZ
                      </Button>
                      </Link>
                      </>
                    );
                  }
                },
            ];
    
        return (
          <ContainerComponent>
            <h1>Wtyczki zestawień statystycznych</h1>
            <div style={{color : 'red'}}>Wtyczki instaluje administrator!</div>
          <div style={{ height: 300, width: '100%' }}>
            <DataGrid rows={rows} columns={columns} checkboxSelection={true} 
               //onRowClick={ this.handleRowClick }
               onSelectionModelChange={(newSelection) => {
                this.setState({...this.state, selectionModel:newSelection});
            }}  />
            { 
              this.state.selectionModel.map(val =>{
                let sel=this.state.StatPlugins.find(o => o.id==val)
                return sel?
                <>
                <h2>{sel.opis}</h2>
                <div>ID = <b>{ sel.id }</b></div> 
                <div>nazwa = <b>{ sel.nazwa }</b></div> 
                </> : <></> }
              )
            }
          </div>
          <Button
           size="small"
           color="info"
           onClick={()=>{alert('Wtyczki obecnie mogą być instalowane tylko na serwerze')} }
          >
           ZAINSTALUJ NOWĄ WTYCZKĘ
          </Button>
          </ContainerComponent>
            
        );
    }
}


const FStatPlugins = () => {
  let navigate = useNavigate();

  const  rowClick = (params: GridRowParams) => {  
    //alert(params.row.id)
    navigate("/ai/details/"+params.row.id)
  }


  return (
   <StatPlugins rowClick={rowClick} navigate={navigate} />
  );
}

const mapStateToProps = (state : ReduxState) => ({
  redux : state
});


export default connect(mapStateToProps)(FStatPlugins)
  
//export default  withRouter(StatPlugins)
