import {  Component } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, 
         MenuItem, Select, SelectChangeEvent, TextField, 
         Typography } from '@mui/material';
import { connect } from 'react-redux';
import { ReduxState, Actions, store} from '../rdx'
import { CParam, DefClause, KBStore } from '../rdx/kb'
  
import ContainerComponent from "../mui/Container";
import KbClause1 from './KbClause1';
import { KbParam, KbService, KbSubClause, ModelClauseDef } from '../api';

import * as scp from '../scopes'

type KbClausesProps = {
  redux? : KBStore
}

type KbClausesState = {
//  name : string;
  descript : string;

    newDefinition : boolean;
    selected : string;
    value : string,
    param : string,
    call : string,

  }

const chkClNew = {
  inputProps: { 'aria-label': 'Nowa definicja' } 
}
const chkClNet  = {
  inputProps: { 'aria-label': 'Dla sieci neuronowych' } 
}

export const rdxClauseDef = (cdef : ModelClauseDef) => {
  let subClauses : DefClause[] = [];
  let params : CParam[] = [];
  cdef.definition.forEach(
    (value: KbSubClause, index: number, array: KbSubClause[]) => {
    try{
      subClauses.push({
        clause_id: value.clause_id,
        name: value.name,
        category: value.category,
        order : value.order
      });
      if (value.params) {
        value.params.forEach(
          (pvalue: KbParam, pindex: number, parray: KbParam[]) => {
            params.push({
              clause_id: value.clause_id,
              name: pvalue.name,
              unifyClause: pvalue.unifyClause,
              unifyParam: pvalue.unifyParam,
              active: pvalue.active,
              value:pvalue.value
            });  
          }
        );  
      }
    }   
    catch (e)   
    {  
      console.log(e);
    }  
    }
  )
  store.dispatch(Actions.reduxKb.setClause2(cdef.id, cdef.name, cdef.description, 
    subClauses, params))
}


class KbClauses extends Component<KbClausesProps, KbClausesState> {

    ident = '';
    
    constructor(props : KbClausesProps){
        super(props);
        this.state={
  //        name : '',
          descript : '',
          newDefinition: true,
          selected : 'wartość',
          value : '',
          param : '',
          call : '',
        }
    }

    async getClauses()
    {
      KbService.getCatClauses('zdefiniowane',{})
      .then(
        (result) => {
          store.dispatch(Actions.reduxKb.setClauses(result.clauses))
        }
      ).catch((err: any) => {
        console.log('Error ' + JSON.stringify(err));
        alert('Error ' + JSON.stringify(err));
      }
      )
    }

    buildDefinition(): KbSubClause[] {
      let definition : KbSubClause[] = [];
      this.props.redux?.subClauses.forEach(
        (scl: DefClause, index1: number, array1: DefClause[]) => {
          let params : KbParam[] = []
          this.props.redux?.params.forEach(
            (par: CParam, index2: number, array2: CParam[]) => {
              if (scl.clause_id===par.clause_id) {
                params.push({
                 name: par.name,
                 unifyClause: par.unifyClause,
                 unifyParam: par.unifyParam,
                 active: par.active,
                 value: par.value
               })
              }
            }
          )
          definition.push({
            clause_id: scl.clause_id,
            name: scl.name,
            category : scl.category,
            order : scl.order,
            params : params
          })
        }
      )
      return definition
    }

    async postClauseDef(operation : string, param : string)
    {
      let definition = this.buildDefinition()
      KbService.postClauseDef(operation, param, 
         { 
          id: this.props.redux?.clId || 0,
          name : this.props.redux?.clName || '?',
          description : this.props.redux?.clDescription || '',
          definition : definition
         }, {})
      .then(
        (result) => {
          rdxClauseDef(result)
        }
      ).catch((err: any) => {
        console.log('Error ' + JSON.stringify(err));
        alert('Error ' + JSON.stringify(err));
      }
      )
    }

    async testQuery()
    {
      let definition = this.buildDefinition()
      KbService.postQuery( 
         { 
          id: this.props.redux?.clId || 0,
          name : this.props.redux?.clName || '?',
          description : this.props.redux?.clDescription || '',
          definition : definition
         }, {})
      .then(
        (result) => {
          alert(JSON.stringify(result))
        }
      ).catch((err: any) => {
        console.log('Error ' + JSON.stringify(err));
        alert('Error ' + JSON.stringify(err));
      }
      )
    }


    async getClause(name : string){
      KbService.getClauseDef(name,{})
      .then(
        (result) => {
          rdxClauseDef(result)
        }
      ).catch((err: any) => {
        console.log('Error ' + JSON.stringify(err));
        alert('Błąd odczytu klauzuli ' + JSON.stringify(err));
      }
      )      
    }

    selClause = (event: SelectChangeEvent) => {
      this.getClause(event.target.value as string)
    }

    selType = (event: SelectChangeEvent) => {
      this.setState({...this.state, selected:  event.target.value as string})
    }
    
    componentDidMount() {  
      this.getClauses()  
    }


    render() {
      if (!scp.allow([scp.SCOPE_KN_READ])) return(<ContainerComponent>Brak dostępu</ContainerComponent>)

      let sub = [<hr key={-1} />];
      
      this.props.redux?.subClauses.forEach(
        (value: DefClause, index: number, array: DefClause[]) =>
        {
          sub.push(
            <Grid item xs={12} key={index} >
            <KbClause1 name={value.name} category={value.category} clause_id={value.clause_id} 
             cIx={ index}/> 
            </Grid>
          
          )
        }
      )
      
 

      return(
<ContainerComponent>
<Grid container spacing={2}>
  <Grid item xs={12}>
  <Typography variant="h4">Definicja zapytania</Typography>
  </Grid>
  <Grid item xs={6}>
     <FormControlLabel
        label="Nowa definicja?"
        control={ <Checkbox {...chkClNew } 
        checked={this.state.newDefinition}
        onChange={()=>{this.setState({...this.state, newDefinition: ! this.state.newDefinition})} } />}
        />
      <FormControlLabel
        label="Dla sieci neuronowych?"
        control={ <Checkbox {...chkClNet } 
        checked={this.props.redux?.clNet}
        onChange={()=>{
          store.dispatch(Actions.reduxKb.setNet(! this.props.redux?.clNet))
         } } />}
        />
      { this.state.newDefinition? 
        <TextField
          id="NName"
          label="Identyfikator"
          helperText="Identyfikator zapytania"
          variant="standard"
          fullWidth
          value={ this.props.redux?.clName }
          onChange={
            (event: React.ChangeEvent<HTMLInputElement>) =>
            {
              store.dispatch(Actions.reduxKb.setClName(event.target.value))
              //this.setState({...this.state, name : event.target.value})  
            }              
          }
        />
        :
        
        <Select
        labelId="clname-helper-label"
        id="clname-select-helper"
        value={ this.props.redux?.clName }
        label="netIdent"
        onChange={ this.selClause }
        fullWidth
        >
          { 
            this.props.redux?.clauses.map(
              (name : string, index : number) => 
                 <MenuItem value={name} key={index}>{name}</MenuItem>
              )
          }
        </Select>
      }
  <div style={{ height: 100, paddingTop:'1em', width: '100%' }}>
    
  <TextField
          id="clause-descript"
          label="Opis"
          multiline
          maxRows={4}
          fullWidth
          value={
            this.props.redux?.clDescription
            //this.state.descript
          }
          onChange={
            (event: React.ChangeEvent<HTMLInputElement>) =>
            {
              //this.setState({...this.state, descript: event.target.value})  
              store.dispatch(Actions.reduxKb.setClDescription(event.target.value))
            }              
          }
        />
  </div>
  </Grid>
  <Grid item xs={6} style={{textAlign: 'center'}}>
  <Button variant="contained" onClick={ ()=>{
                  if (! scp.allow([scp.SCOPE_KN_WRITE])) alert('Brak uprawnień!') 
                  else this.postClauseDef('s','')
  }  }> - ZAPISZ&nbsp;&nbsp;&nbsp;DEFINICJE - </Button> 
  <br /><br />
  <Button variant="outlined" onClick={ ()=>{
//      store.dispatch(Actions.reduxKb.newSubClause())
    this.postClauseDef('d+','')
     } }>DODAJ PODZAPYTANIE</Button>
  <br /><br />
  <Button variant="outlined" onClick={ ()=>{
        if (! scp.allow([scp.SCOPE_KN_QUERY])) alert('Brak uprawnień!') 
        else this.testQuery()
     } }>TESTUJ WYBÓR DANCH</Button>

  </Grid>
  { 
  sub 
  }
</Grid>            
{ this.props.redux?.subClauses.map(
    (cl : DefClause, index : number) => 
      <div key={index}>{cl.name}</div>              )  
}              
          </ContainerComponent>
            
        );
    }
}

const mapStateToProps = (state : ReduxState) => ({
  redux : state.reduxKb
});
  
export default connect(mapStateToProps)(KbClauses)
  

