import * as React from "react";
import ContainerComponent from "./mui/Container";
import Typography from "@mui/material/Typography";
import Login from './Login'

const Component = () => {
  return (
    <ContainerComponent>
      <Typography paragraph>
 Strona projektu badawczego, w którym połączono metody statytyki i sztucznej inteligencji 
 do szacowania wartości nieruchomości.<br />
 Podstawową do obliczeń jest model współczynnikowy (funkcja Szacowanie).<br />
 <b>Sieć neuronowa</b> może być wykorzystana do oszacowania ceny alternatywnym sposobem. 
 Te wyliczenia mogą zostać ujęte w modelu współczynnikowym w postaci jednego ze współczynników.<br />
 Z kolei <b>Baza Wiedzy</b> to mechanizm gromadzenia z różnych źródeł informacji, które mogą stanowić 
 dane wejściowe dla sieci neuronowej.
       </Typography>
       <Typography paragraph>
 <b>Dostęp do danych wymaga zalogowania się.</b>.
 <br />
 <br />
 <span>Login demo/demo pozwala na przeglądanie danych (częściowo zaszyfrowanych z uwagi na ochronę danych) - 
  bez możliwości ich modyfikacji</span>


       </Typography>
       <Login />
     </ContainerComponent>
  );
};

export default Component;
