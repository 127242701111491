import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";

import '@toast-ui/editor/dist/toastui-editor.css';

import Estates from "./estates/Estates";
import JCModel0 from "./estates/JCModel";
import JCModel2 from "./estates/JCModel2";

import KbClauses from "./kb/KbClauses";
import KbSources from "./kb/KbSources";
import KbEvents from "./kb/KbEvents";

import NNet from "./nnet/NNet";
import NTraining from "./nnet/NTraining";
import NPredict from "./nnet/NPredict";

import { Provider } from 'react-redux';
import { store }  from './rdx';
import Transactions from "./estates/Transactions";
import StatPlugins from "./stat/StatPlugins";

/*
import Camera from "./ts/vision/Camera";
import Image from "./ts/vision/Image";
import Plot from "./ts/realestate/Plot";
import MultiLayer from "./ts/tensor/MultiLayer";
import Classification from "./ts/tensor/Classification";
import BrainStore from "./ts/brainjs/Store";
// Drag & Drop
import KBResults from "./lab/dnd/KBResults";
*/


const rootElement = document.getElementById("root");

render(
  <Provider store={store}>
  <BrowserRouter>
    <Routes>
      <Route path="/ai/estates" element={<Estates />} />
      <Route path="/ai/trans" element={<Transactions />} />
      <Route path="/ai/jcmodel" element={<JCModel2 oid={57} />} />      
      <Route path="/ai/jcmodel2/:oid" element={<JCModel2/>} />
      <Route path="/ai/jcmodel1" element={<JCModel0 />} />      

      <Route path="/ai/kbclauses" element={<KbClauses />} />
      <Route path="/ai/kbsrc" element={<KbSources />} />     
      <Route path="/ai/kbevents" element={<KbEvents />} />
    
      <Route path="/ai/ndef" element={<NNet />} />
      <Route path="/ai/ntrain" element={<NTraining />} />
      <Route path="/ai/npredict" element={<NPredict />} />

      <Route path="/ai/stat" element={<StatPlugins />} />

  
      <Route path="/ai/*" element={<App />} />
      <Route path="/*" element={<App />} />
    </Routes>
  </BrowserRouter>
  </Provider>,
  rootElement
);

/* próby - sieć neuronowa w Typescript:

      <Route path="/ai/plot" element={<Plot />} />
      <Route path="/ai/camera" element={<Camera />} />
      <Route path="/ai/image" element={<Image />} />
      <Route path="/ai/multilayer" element={<MultiLayer />} />
      <Route path="/ai/classification" element={<Classification />} />

      <Route path="/ai/dnd" element={<KBResults />} />
      <Route path="/ai/kbfact" element={<KbFacts />} />

  
*/