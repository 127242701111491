import { Component } from 'react';
import {Obiekt, MService } from '../api'
import { DataGrid, plPL, GridColDef, GridRowId } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { Actions, ReduxState, store } from '../rdx'
import { EstatesStore  } from '../rdx/estates'
import { Grid } from '@mui/material';
import SelCategories from './SelCategories'


type SelTransProps = {
  redux? : EstatesStore;
  navigate? : any;
}

type SelTransState = {
//    selectionModel : GridRowId[];
    lista : Obiekt[];
    kategorie : string[]
}

class SelTrans extends Component<SelTransProps, SelTransState> {
   


    constructor(props : SelTransProps){
        super(props);
        
        this.state={
            kategorie:[],
            //selectionModel : [],
            lista:[]
        }
    }


    async getTransactions(nowe : string[])
    {
      let par:string[]=[]
      if (nowe.length>0) par=nowe
      else if ((this.props.redux) && (this.props.redux.wybrane_kategorie.length>0))
        par=this.props.redux.wybrane_kategorie.slice()
      let wczytane : boolean = false;
      if ((this.state.kategorie.length===par.length)) {
        wczytane = true
        for (let i=0; i<par.length; i++) {
          if (this.state.kategorie[i] !== par[i]) {
            wczytane=false
          }
        }
      }
      if (wczytane) return;
    
      MService.getTransListaK(this.props.redux?.obiekt?.id||0, par,{})
         .then(
             (result ) => {  
               let lista = result.obiekty.slice()
               lista.forEach(
                (o: Obiekt, index: number, array: Obiekt[]) =>{
                    if (! (par.indexOf(o.kategoria)>=0)) {
                      par.push(o.kategoria)
                    }
                 })
               this.setState({...this.state, kategorie:par, lista: lista})
             }
          ).catch((err: any) => {
              console.log('Error ' + JSON.stringify(err));
              alert('Error ' + JSON.stringify(err));
            }
        )      
    }

    componentDidMount() {  
      //this.getTransactions([])  
    }

    
    render() {
     if ( (! this.props.redux) || (this.props.redux.wybrane_kategorie.length===0) ){
      if (this.props.redux?.obiekt) {
         //store.dispatch(Actions.reduxEstates.setWybraneKategorie(['H1','H2','H3']))
         store.dispatch(Actions.reduxEstates.setWybraneKategorie([this.props.redux?.obiekt.kategoria]))
      }
      return(
         <div> Nie wybrano kategorii! </div>
       )
     } else {
        this.getTransactions(this.props.redux.wybrane_kategorie)  
        let rows = [];
        if (this.state.lista) {
           for (let o of this.state.lista) {
            let m2=o.powierzchnia; // m2=parseFloat(o.powierzchnia);
            rows.push(
              { key: o.id, 
                id: o.id, 
                obiekt: o.obiekt,
                kategoria: o.kategoria, 
                lokalizacja: o.lokalizacja,
                opis: o.opis,
                geoportal: o.geoportal,
                adres: o.adres,
                cena: o.cena,
                cenaTransakcji: o.cenaTransakcji,
                dochod: o.dochod,
                powierzchnia: o.powierzchnia,
                cena_jedn: m2>0?(o.cenaTransakcji/m2):0          
              },
            )
          }
        }
        const columns: GridColDef[] = [
                { field: 'id', headerName: 'id', width: 75 },
                { field: 'kategoria', headerName: 'kategoria', width: 100 },
                { field: 'obiekt', headerName: 'Obiekt', width: 150 },
                { field: 'lokalizacja', headerName: 'Lokalizacja', width: 250 },
                { field: 'opis', headerName: 'Opis', width: 350 },
                { field: 'cenaTransakcji', headerName: 'Cena', width: 150,  type: 'number', },
                { field: 'powierzchnia', headerName: 'm2', width: 100,  type: 'number' },
                { field: 'cena_jedn', headerName: 'cena jedn.', width: 100,  type: 'number' },
            ];
    
            //setSelection([1220]);
            //alert(JSON.stringify(this.state.selectionModel))
        return (
          <>
          <Grid item xs={12}>
            <h3>Zaznacz transakcje wybrane do modelu</h3>
            <SelCategories onChange={
               (par)=>{ this.getTransactions(par) }
            } />
            </Grid>
          
          <Grid item xs={12}>
          <div style={{ height: 500, width: '100%' }}>
            <DataGrid rows={rows} columns={columns} 
              checkboxSelection
              //={true} 
              localeText={plPL.components.MuiDataGrid.defaultProps.localeText} 
               //pageSize={10}
               //rowsPerPageOptions={[5, 10, 20]}
               selectionModel={ 
                 this.props.redux.wybrane
                 //this.state.selectionModel
                }
               onSelectionModelChange={(newSelection) => {
                 if (newSelection.length>0) {
                   let sel : number[] = []
                   newSelection.map((w,i)=>{
                     sel.push(typeof w === "number" ? w : parseInt(w, 10))
                     return i
                   })
                   store.dispatch(Actions.reduxEstates.setWybrane(sel))
//                  store.dispatch(Actions.reduxEstates.clrSelected())
//                  this.setState({...this.state, selectionModel:newSelection})
  
                 }
              }}  />
              
          </div>
          </Grid>
            <Grid item xs={12} >
            { /*
              this.state.selectionModel.map((val,i) =>{
                let sel=null;
                if (this.state.lista) {
                  let lo=this.state.lista;
                  if (lo) {
                     sel=lo.find(o => o.id===val)
                     if (sel) 
                       store.dispatch(Actions.reduxEstates.addSelected(sel.id))
                    }                  
                }
                return ""
                }
              )*/
            }

            </Grid>
          
          </>
            
        );
      }
  }
}


const FTransactions = (props : SelTransProps) => {
  let navigate = useNavigate();

  return (
   <SelTrans {...props} navigate={navigate} />
  );
}

const mapStateToProps = (state : ReduxState) => ({
  redux : state.reduxEstates
});


export default connect(mapStateToProps)(FTransactions)
  