import React from 'react';
import type { EditorProps } from './tui';
 
import { Editor  } from '@toast-ui/react-editor';
import ToastuiEditor from '@toast-ui/editor';

type  EProps  = EditorProps & {
//  content : string,
  _oid : number,
  onSave? : (oid:number,text: string)=>void
}

class MyEditor extends React.Component<EProps> {
  editorRef : React.RefObject<any> = React.createRef();

  handleSaveClick = () => {
    let inst : ToastuiEditor /*ToastuiEditorViewer */=this.editorRef.current.getInstance();
 //   alert(JSON.stringify(inst.getMarkdown()))
    /*  let re : HTMLElement = this.editorRef.current.getRootElement() */
    if (this.props.onSave) this.props.onSave(this.props._oid,inst.getMarkdown());
  };


  render() {
    return (
      <>
        <Editor
          previewStyle="vertical"
          height="400px"
          initialEditType="markdown"
          initialValue={this.props.initialValue}
          ref={this.editorRef}
        />
        <button onClick={this.handleSaveClick}>Zapisz</button>
      </>
    );
  }
}


type OParams = {
  oid : number,
  content0 : string,
  onSave? : (oid:number,text: string)=>void
}
 

const FEditor = function (props: OParams) {
  let cnt = props.content0? props.content0.replace(/\\n/g, '\n'):'';
  return <MyEditor 
     previewStyle="vertical"
     _oid = { props.oid }
     onSave = { props.onSave }     
     initialValue={cnt} 
     height="400px" >       
    </MyEditor>;
};


export default FEditor;
