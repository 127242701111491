import { store } from './rdx'

export const SCOPE_TEST_DATA_READ=1
export const SCOPE_TEST_DATA_WRITE=2
export const SCOPE_TEST_DATA_NEW=3
export const SCOPE_TEST_DATA_DELETE=4
export const SCOPE_TEST_PREDICT=5
export const SCOPE_ESTATES_READ_HASHED=6
export const SCOPE_ESTATES_READ=7
export const SCOPE_ESTATES_WRITE_ATTRIBS=8
export const SCOPE_ESTATES_WRITE=9
export const SCOPE_ESTATES_NEW=10
export const SCOPE_ESTATES_DELETE=11
export const SCOPE_ESTATES_DETAILS=12
export const SCOPE_ESTATES_APPRAISAL_REPORT=13
export const SCOPE_ESTATES_PREDICT=14
export const SCOPE_TRANSACTIONS_READ_HASHED=15
export const SCOPE_TRANSACTIONS_READ=16
export const SCOPE_TRANSACTIONS_WRITE_ATTRIBS=17
export const SCOPE_TRANSACTIONS_WRITE=18
export const SCOPE_TRANSACTIONS_NEW=19
export const SCOPE_TRANSACTIONS_DELETE=20
export const SCOPE_KN_READ=21
export const SCOPE_KN_QUERY=22
export const SCOPE_KN_WRITE=23
export const SCOPE_NNET_READ=24
export const SCOPE_NNET_WRITE=25
export const SCOPE_NNET_TRAIN_ORDER=26
export const SCOPE_NNET_TRAIN=27
export const SCOPE_NNET_PREDICT=28

const consequences : number[][] = [];

consequences[SCOPE_TEST_DATA_READ] = [SCOPE_TEST_DATA_READ]
consequences[SCOPE_TEST_DATA_WRITE] = [SCOPE_TEST_DATA_WRITE]
consequences[SCOPE_TEST_DATA_NEW] = [SCOPE_TEST_DATA_NEW]
consequences[SCOPE_TEST_DATA_DELETE] = [SCOPE_TEST_DATA_DELETE]
consequences[SCOPE_TEST_PREDICT] = [SCOPE_TEST_PREDICT]
consequences[SCOPE_ESTATES_READ_HASHED] = [SCOPE_ESTATES_READ,SCOPE_ESTATES_READ_HASHED]
consequences[SCOPE_ESTATES_READ] = [SCOPE_ESTATES_READ,SCOPE_ESTATES_READ_HASHED]
consequences[SCOPE_ESTATES_WRITE_ATTRIBS] = [SCOPE_ESTATES_WRITE_ATTRIBS,SCOPE_ESTATES_READ_HASHED]
consequences[SCOPE_ESTATES_WRITE] = [SCOPE_ESTATES_WRITE,SCOPE_ESTATES_WRITE_ATTRIBS,SCOPE_ESTATES_READ_HASHED]
consequences[SCOPE_ESTATES_NEW] = [SCOPE_ESTATES_NEW,SCOPE_ESTATES_WRITE,SCOPE_ESTATES_WRITE_ATTRIBS,SCOPE_ESTATES_READ_HASHED]
consequences[SCOPE_ESTATES_DELETE] = [SCOPE_ESTATES_DELETE,
            SCOPE_ESTATES_NEW,SCOPE_ESTATES_WRITE,SCOPE_ESTATES_WRITE_ATTRIBS,SCOPE_ESTATES_READ_HASHED]
consequences[SCOPE_ESTATES_DETAILS] = [SCOPE_ESTATES_DETAILS,SCOPE_ESTATES_READ,SCOPE_ESTATES_READ_HASHED]
consequences[SCOPE_ESTATES_APPRAISAL_REPORT] = [SCOPE_ESTATES_APPRAISAL_REPORT]
consequences[SCOPE_ESTATES_PREDICT] = [SCOPE_ESTATES_PREDICT]
consequences[SCOPE_TRANSACTIONS_READ_HASHED] = [SCOPE_TRANSACTIONS_READ_HASHED,SCOPE_TRANSACTIONS_READ]
consequences[SCOPE_TRANSACTIONS_READ] = [SCOPE_TRANSACTIONS_READ_HASHED,SCOPE_TRANSACTIONS_READ]
consequences[SCOPE_TRANSACTIONS_WRITE_ATTRIBS] = [SCOPE_TRANSACTIONS_WRITE_ATTRIBS,SCOPE_TRANSACTIONS_READ_HASHED,SCOPE_TRANSACTIONS_READ]
consequences[SCOPE_TRANSACTIONS_WRITE] = [SCOPE_TRANSACTIONS_WRITE, SCOPE_TRANSACTIONS_WRITE_ATTRIBS,
           SCOPE_TRANSACTIONS_READ_HASHED,SCOPE_TRANSACTIONS_READ]
consequences[SCOPE_TRANSACTIONS_NEW] = [SCOPE_TRANSACTIONS_NEW,
    SCOPE_TRANSACTIONS_WRITE, SCOPE_TRANSACTIONS_WRITE_ATTRIBS,
    SCOPE_TRANSACTIONS_READ_HASHED,SCOPE_TRANSACTIONS_READ]
consequences[SCOPE_TRANSACTIONS_DELETE] = [SCOPE_TRANSACTIONS_DELETE,SCOPE_TRANSACTIONS_NEW,
    SCOPE_TRANSACTIONS_WRITE, SCOPE_TRANSACTIONS_WRITE_ATTRIBS,
    SCOPE_TRANSACTIONS_READ_HASHED,SCOPE_TRANSACTIONS_READ]
consequences[SCOPE_KN_READ] = [SCOPE_KN_READ]
consequences[SCOPE_KN_QUERY] = [SCOPE_KN_QUERY]
consequences[SCOPE_KN_WRITE] = [SCOPE_KN_WRITE,SCOPE_KN_QUERY,SCOPE_KN_READ]
consequences[SCOPE_NNET_READ] = [SCOPE_NNET_READ]
consequences[SCOPE_NNET_WRITE] = [SCOPE_NNET_READ,SCOPE_NNET_WRITE]
consequences[SCOPE_NNET_TRAIN_ORDER] = [SCOPE_NNET_TRAIN_ORDER]
consequences[SCOPE_NNET_TRAIN] = [SCOPE_NNET_TRAIN]
consequences[SCOPE_NNET_PREDICT] = [SCOPE_NNET_PREDICT]




export const allow = (oscopes : number[]) => {
  if (oscopes.length===0) return true;
  if (! store.getState().reduxLogin.token) return false;
  let ls : string[] = store.getState().reduxLogin.scopes?.split(',')||[]
  let scopes : number[] = []
  for (let scope of ls) {
    scopes=scopes.concat(consequences[parseInt(scope)])
  }
//  alert(JSON.stringify(scopes))
  for (let scope of oscopes) {
    if (! (scopes.indexOf(scope)>=0)) return false
  }
  return true
}