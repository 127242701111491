import { Component, useEffect, useState } from 'react';

import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button, Grid, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';

import { Obiekt, MService, DService } from '../api'


import { Editor } from '../md'
//import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Actions, ReduxState, store } from '../rdx'
import { EModule, EstatesStore } from '../rdx/estates'

import JCModel1 from './JCModel1'
import Attaches from './Attaches'
import * as scp from '../scopes'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  oid: number;
  obiekt: Obiekt
}


function TabPanelEditor(props: TabPanelProps) {
  const { children, value, index, oid, obiekt, ...other } = props;

  const postOpis = async (oid: number, text: string) => {
    MService.zapiszOpis(oid, text, {})
      .then(
        (result) => {
          if (result.kod = 0)
            alert('Zapisano')
          else
            alert(result.opis)
        }
      ).catch((err: any) => {
        console.log('Error ' + JSON.stringify(err));
        alert('Błąd zapisu! ')
      }
      )
  }


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant='h4'>Opis</Typography>
          <Editor oid={oid}
            content0={props.obiekt.opis}
            onSave={(oid: number, text: string) => {
              if (! scp.allow([scp.SCOPE_ESTATES_WRITE])) alert('Brak uprawnień!') 
              else postOpis(oid, text);
            }}
          />

        </Box>
      )}
    </div>
  );
}


function TabPanelAttr(props: TabPanelProps) {
  const { children, value, index, oid, obiekt, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant='h4'>Obiekt {oid}</Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="edObiekt"
                //label="Nazwa obiektu"
                //defaultValue=""
                helperText="Krótka nazwa"
                variant="standard"
                fullWidth
                value={obiekt.obiekt}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    store.dispatch(Actions.reduxEstates.setObiekt(oid, { ...obiekt, obiekt: event.target.value }))
                  }
                }
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel htmlFor="val_cena">Powierzchnia</InputLabel>
              <OutlinedInput
                id="val_cena"
                value={obiekt.powierzchnia}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    store.dispatch(Actions.reduxEstates.setObiekt(oid, { ...obiekt, powierzchnia: parseFloat(event.target.value) }))
                  }
                }
                endAdornment={<InputAdornment position="end"> m<sup>2</sup> </InputAdornment>}
                label="Powierzchnia"
              />

            </Grid>
            <Grid item xs={8}>
              <TextField
                id="edLokalizacja"
                label="Lokalizacja"
                //defaultValue=""
                helperText="Lokalizacja - arkusz"
                variant="standard"
                fullWidth
                value={obiekt.lokalizacja}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    store.dispatch(Actions.reduxEstates.setObiekt(oid, { ...obiekt, lokalizacja: event.target.value }))
                  }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="edPrzeznaczenie"
                label="Przeznaczenie"
                size="small"
                /*          defaultValue=""
                          maxRows={4}
                          multiline
                          fullWidth
                          helperText="Przeznaczenie - krótko"
                          */
                value={obiekt.przeznaczenie}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    store.dispatch(Actions.reduxEstates.setObiekt(oid, { ...obiekt, przeznaczenie: event.target.value }))
                  }
                }
              />
              <TextField
                id="fm_kategoria"
                size="small"
                value={obiekt.kategoria}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    store.dispatch(Actions.reduxEstates.setObiekt(oid,
                      {
                        ...obiekt,
                        kategoria: event.target.value
                      }))
                  }
                }
                label="Kategoria"
              />

            </Grid>
            <Grid item xs={12}>
              <TextField
                id="edAdres"
                label="Adres"
                //defaultValue=""
                //helperText="Adres"
                variant="standard"
                fullWidth
                value={obiekt.adres}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    store.dispatch(Actions.reduxEstates.setObiekt(oid, { ...obiekt, adres: event.target.value }))
                  }
                }
              />
            </Grid>
          </Grid>

        </Box>
      )}
    </div>
  );
}


//////////////
function TabPanelNew(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const [nazwa, setNazwa] = useState<string>('');
  const [powierzchnia, setPowierzchnia] = useState<number>(0);
  const [lokalizacja, setLokalizacja] = useState<string>('');
  const [przeznaczenie, setPrzeznaczenie] = useState<string>('');
  const [kategoria, setKategoria] = useState<string>('');
  const [adres, setAdres] = useState<string>('');


  const postNew = async ()  => {
      let o = new Obiekt({
        obiekt : nazwa,
        powierzchnia : powierzchnia,
        lokalizacja : lokalizacja,
        przeznaczenie : przeznaczenie,
        kategoria : kategoria,
        adres : adres
      })
      MService.dodanieObiektu(o, {})
        .then(
          (result) => {
            if (result.kod = 0)
              alert('Zapisano')
            else
              alert(result.opis)
          }
        ).catch((err: any) => {
          console.log('Error ' + JSON.stringify(err));
          alert('Błąd zapisu! Spróbuj ponownie!')
          //alert('Error ' + err);
        }
        )

        MService.getObiektLista(250,1,'',{})
        .then(
            (result ) => {  
             store.dispatch(Actions.reduxEstates.setLista(EModule.estates, result))     
            }
         ).catch((err: any) => {
             console.log('Error ' + JSON.stringify(err));
             alert('Error ' + JSON.stringify(err));
           }
       )      
  
  }
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >

        <Box sx={{ p: 3 }}>
          <Typography variant='h4'>Nowy obiekt</Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="edObiekt"
                //label="Nazwa obiektu"
                //defaultValue=""
                helperText="Krótka nazwa"
                variant="standard"
                fullWidth
                value={nazwa}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    setNazwa(event.target.value)
                  }
                }
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel htmlFor="val_cena">Powierzchnia</InputLabel>
              <OutlinedInput
                id="val_cena"
                value={powierzchnia}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    setPowierzchnia(parseInt(event.target.value))
                  }
                }
                endAdornment={<InputAdornment position="end"> m<sup>2</sup> </InputAdornment>}
                label="Powierzchnia"
              />

            </Grid>
            <Grid item xs={8}>
              <TextField
                id="edLokalizacja"
                label="Lokalizacja"
                //defaultValue=""
                helperText="Lokalizacja - arkusz"
                variant="standard"
                fullWidth
                value={lokalizacja}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    setLokalizacja(event.target.value)
                  }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="edPrzeznaczenie"
                label="Przeznaczenie"
                size="small"
                value={przeznaczenie}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    setPrzeznaczenie(event.target.value)
                  }
                }
              />
              <TextField
                id="fm_kategoria"
                size="small"
                value={kategoria}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    setKategoria(event.target.value)
                  }
                }
                label="Kategoria"
              />

            </Grid>
            <Grid item xs={12}>
              <TextField
                id="edAdres"
                label="Adres"
                //defaultValue=""
                //helperText="Adres"
                variant="standard"
                fullWidth
                value={adres}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    setAdres(event.target.value)
                  }
                }
              />
            </Grid>
          </Grid>
          <Grid>
          <Button variant="outlined" onClick={() => {
            if (! scp.allow([scp.SCOPE_ESTATES_NEW])) alert('Brak uprawnień!') 
            else postNew()}}>DODAJ NOWY</Button>
          </Grid>

        </Box>
    </div>
  )
}


//////////////
function TabPanelJC(props: TabPanelProps) {
  const { children, value, index, oid, obiekt, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant='h4'>Atrybuty szacowania w modelu JC</Typography>
          <JCModel1 oid={oid} />
        </Box>
      )}
    </div>
  );
}

function TabPanelAttaches(props: TabPanelProps) {
  const { children, value, index, oid, obiekt, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant='h4'>Zdjęcia, dokumenty i inne pliki</Typography>
          <Attaches oid={oid} />
        </Box>
      )}
    </div>
  );
}

type OParams = {
  oid?: number;
  redux?: EstatesStore
}

type EstatesState = {
  value: number,
  kategoria: string;
  obiekt?: Obiekt
}

class Estate extends Component<OParams, EstatesState> {

  constructor(props: OParams) {
    super(props);
    this.state = {
      kategoria: '',
      value: 0
    }
  }


  atrybuty() {
    
    let oid = this.props.oid || 0;
    
    if (oid !== this.props.redux?.oid) return [];
    let lista: any[] = []

    try {
      
      let s = this.props.redux?.atrybuty
      

      if (typeof (s) == 'string') {
        s = s.replace(/\'/g, '"')

        let aa = JSON.parse(s)
        Object.keys(aa).map(
          (key, index) => {
            let value = aa[key];
            let svalue = "[struktura]";
            if (typeof (value) == 'number') svalue = value.toString()
            else if (typeof (value) == 'string') svalue = value
            lista.push(<li key={index}>{key}: <b> {svalue} </b> </li>)
            return key
          }
        )

      }
    } catch (error) {
      alert('Błąd??')
      alert(error)
    }
    return lista
  }

  async getEstate() {
    let oid = this.props.oid || 0
    if (oid > 0) {
      MService.getObiekt(oid.toString(), {})
        .then(
          (result) => {
            store.dispatch(Actions.reduxEstates.setObiekt(oid, result))
          }
        ).catch((err: any) => {
          //store.dispatch(Actions.reduxEstates.setObiekt(0,new Obiekt({})))
          store.dispatch(Actions.reduxEstates.setOid(0))
          alert('Błąd odczytu danych szczegółowych');
          alert(err);
          console.log('Error ' + JSON.stringify(err));
        }
        )
    }
  }

  async getAttr() {
    let oid = this.props.oid || 0
    if (oid > 0) {
      DService.getElementDet(oid.toString(), {})
        .then(
          (result) => {

            store.dispatch(Actions.reduxEstates.setAtrybuty(result.atrybuty))
          }
        ).catch((err: any) => {
          console.log('Error1 ')
          console.log(err);
          alert('Błąd odczytu atrybutów ')
          alert(err);
        }
        )

    }
  }


  async postObiekt() {
    if (this.props.redux) {
      let o = new Obiekt({
        ...this.props.redux.obiekt,

      })
      MService.zmianaDanychObiektu(o, {})
        .then(
          (result) => {
            if (result.kod = 0)
              alert('Zapisano')
            else
              alert(result.opis)
          }
        ).catch((err: any) => {
          console.log('Error ' + JSON.stringify(err));
          alert('Błąd zapisu! Spróbuj ponownie!')
          //alert('Error ' + err);
        }
        )
    }
  }

  handleChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({ ...this.state, value: newValue });
  };

  componentDidMount() {
    //this.getEstate()  
  }

  render() {
    let oid = this.props.oid || 0;
    if (!this.props.redux?.oid || (oid !== this.props.redux?.oid)) {
      this.getEstate()
      this.getAttr()
      return (<div>....</div>)
    }
    return (

      <div style={{ height: 300, width: '100%' }}>
        {this.props.redux?.obiekt ? <>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={this.state.value} onChange={this.handleChange} aria-label="informacje o nieruchomości">
                <Tab label="Atrybuty szacowania" {...a11yProps(0)} />
                <Tab label="Ewidencja" {...a11yProps(1)} />
                <Tab label="Opis" {...a11yProps(2)} />
                <Tab label="Załączniki" {...a11yProps(3)} />
                <Tab label="Opis atrybutów" {...a11yProps(4)} />
                <Tab label="Nowa" {...a11yProps(5)} />
              </Tabs>
            </Box>
            <TabPanelJC value={this.state.value} index={0} oid={this.props.redux.oid} obiekt={this.props.redux.obiekt} />
            <TabPanelAttr value={this.state.value} index={1} oid={this.props.redux.oid} obiekt={this.props.redux.obiekt} />
            <TabPanelEditor value={this.state.value} index={2} oid={this.props.redux.oid}
              obiekt={this.props.redux.obiekt} />
            <TabPanelAttaches value={this.state.value} index={3} oid={this.props.redux.oid} obiekt={this.props.redux.obiekt} />
            <div
              role="tabpanel"
              hidden={this.state.value != 4}
              id={`simple-tabpanel-4`}
              aria-labelledby={`simple-tab-4`}
            >
              { //div dangerouslySetInnerHTML={ {__html : this.props.redux.atrybuty || '' } 
              }
              { //
                this.atrybuty()
              }
            </div>
            <TabPanelNew value={this.state.value} index={5} oid={0} obiekt={new Obiekt()}/>

          </Box>

          {((this.state.value === 0) || (this.state.value === 1)) ?
            <Button variant="outlined" onClick={() => {
              
                if (! scp.allow([scp.SCOPE_ESTATES_WRITE])) alert('Brak uprawnień!') 
                else this.postObiekt()
              
              }
            }>ZAPISZ</Button> : <></>}
        </> : <>Nie znaleziono danych o nieruchomości!!!</>}
      </div>


    );

  }

}

const mapStateToProps = (state: ReduxState) => ({
  redux: state.reduxEstates
});


export default connect(mapStateToProps)(Estate)