import {  Component } from 'react';
import { DataGrid, GridColDef, GridRowParams, GridRowId } from '@mui/x-data-grid';
import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Rating, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';

import { connect } from 'react-redux';
import { Actions, ReduxState, store } from '../rdx'
import { NNetStore  } from '../rdx/nnet'
import { Level, /*NNetDefinition,*/ NNetService } from '../api';

import ContainerComponent from "../mui/Container";
import * as scp from '../scopes'

type NNetProps = {
  redux? : NNetStore
}


type NNetState = {
    crNew : boolean;
    nname : string,
    
    bname : string,
    lname : string,
    units : number,
    inputs : number,
    activation : string,

    selectionModel : GridRowId[]
  
  }

const chkbNewAttrs = {
  inputProps: { 'aria-label': 'Nowa definicja' } 
}

class NNet extends Component<NNetProps, NNetState> {

    ident = '';
    
    constructor(props : NNetProps){
        super(props);
        this.state={
            crNew: false,
            nname : '',

            lname : 'hidden 2',
            bname : 'hidden 2',
            units : 32,
            inputs : 0,
            activation : 'relu',
            selectionModel : []
                    }
    }

    rowClick = (params: GridRowParams) => {  
      this.setState({
         ...this.state,
         lname : params.row.name,
         bname : params.row.name,
         units : params.row.units,
         inputs : params.row.inputs,
         activation : params.row.activation,
      })
    }


    async getNNet(name : string)
    {
      NNetService.getNNet(name,{})
      .then(
          (result ) => {  
            store.dispatch(Actions.reduxNNet.setDefinition(result))
          }
       ).catch((err: any) => {
           console.log('Error ' + JSON.stringify(err));
           alert('Error ' + JSON.stringify(err));
         }
     ) 


/*      let def = new NNetDefinition({
        name : name,
        test_size:0.2,
        source:1,
        levels : [
          {
            name : 'input',
            units : 100,
            inputs : 6,
            activation : 'relu',
          },
          {
            name : 'hidden 1',
            units : 100,
            inputs : 100,
            activation : 'relu',
          },
          {
            name : 'output',
            units : 1,
            inputs : 100,
            activation : 'linear',
          }

        ]
      })
      store.dispatch(Actions.reduxNNet.setDefiniction(def))
      */
    }

    async getNames()
    {
      NNetService.getNNetLista({})
      .then(
          (result ) => {  
            store.dispatch(Actions.reduxNNet.setNames(result.nets))
            this.getNNet( this.props.redux?.name || '' )
          }
       ).catch((err: any) => {
           console.log('Error ' + JSON.stringify(err));
           alert('Error ' + JSON.stringify(err));
         }
     ) 
    }

    async getSources()
    {
      NNetService.getSources({})
      .then(
          (result ) => {  
            store.dispatch(Actions.reduxNNet.setSources(result.sources))
            this.getNNet( this.props.redux?.name || '' )
          }
       ).catch((err: any) => {
           console.log('Error ' + JSON.stringify(err));
           alert('Error ' + JSON.stringify(err));
         }
     ) 
    }

    
    async Update()
    {
      if (this.props.redux) {
        
        NNetService.postNNet({
          id:this.props.redux?.id,
          name: this.props.redux.name,
          test_size:this.props.redux.test_size,
          source:this.props.redux.source,
          levels : this.props.redux.levels
        },{})
        .then(
            (result ) => {  
             alert('Zapisano')
            }
         ).catch((err: any) => {
             console.log('Error ' + JSON.stringify(err));
             alert('Error ' + JSON.stringify(err));
           }
       ) 
  
      }
    }

    selNet = (event: SelectChangeEvent) => {
      this.getNNet( event.target.value as string )
    }

    componentDidMount() {  
      this.getSources();
      this.getNames()  
    }

    newLevel = () => {
      if (this.props.redux) {
        let ll = this.props.redux.levels;
        ll.push(
          {
              name: this.state.lname, 
              units: this.state.units,
              inputs: this.state.inputs,
              activation: this.state.activation          
          }
        )
        store.dispatch(Actions.reduxNNet.setLevels(ll))
      }
    }

    updateLevel = () => {
      if (this.props.redux) {
        let ll : Level[] = [];
        this.props.redux.levels.map(
          (v : Level, i : number) => {
            if (v.name==this.state.bname) {
              ll.push(
                {
                    name: this.state.lname, 
                    units: this.state.units,
                    inputs: this.state.inputs,
                    activation: this.state.activation          
                })
            } else  {
              ll.push(
              {
                  name: v.name, 
                  units: v.units,
                  inputs: v.inputs,
                  activation: v.activation          
              }
              )
            }
          }
        )
        store.dispatch(Actions.reduxNNet.setLevels(ll))
      }
    }

    deleteLevel = () => {
      if (this.props.redux) {
        let ll : Level[] = [];
        this.props.redux.levels.map(
          (v : Level, i : number) => {
            if (v.name==this.state.bname) {
            } else  {
              ll.push(
              {
                  name: v.name, 
                  units: v.units,
                  inputs: v.inputs,
                  activation: v.activation          
              }
              )
            }
          }
        )
        store.dispatch(Actions.reduxNNet.setLevels(ll))
      }
    }

    render() {
      if (!scp.allow([scp.SCOPE_NNET_READ])) return(<ContainerComponent>Brak dostępu</ContainerComponent>)


      let rows = [];
      let i = 0
      if (this.props.redux && this.props.redux.levels && this.props.redux.levels.length>0)
        for (let o of this.props.redux.levels) {
        i++
        rows.push(
              { id: i, 
                name: o.name, 
                units: o.units,
                inputs: o.inputs,
                activation: o.activation,
              },
          )
        }
      const columns: GridColDef[] = [
              { field: 'id', headerName: 'id', width: 50 },
              { field: 'name', headerName: 'Nazwa', width: 150 },
              { field: 'units', headerName: 'Ilość', width: 150 },
              { field: 'inputs', headerName: 'Wejścia', width: 150 },
              { field: 'activation', headerName: 'Funkcja aktywująca', width: 250 },
          ];


            return (
          <ContainerComponent>
          <div style={{width: '100%' }}>
            
<Grid container spacing={2}>
  <Grid item xs={12}>
  <Typography variant="h4">Definicja sieci neuronowej</Typography>
  </Grid>
  <Grid item xs={4}>
     <FormControlLabel
        label="Nowa definicja?"
        control={ <Checkbox {...chkbNewAttrs } 
        checked={this.state.crNew}
        onChange={()=>{this.setState({...this.state, crNew: ! this.state.crNew})} } />}
        />
        
      { this.state.crNew? 
        <TextField
          id="NName"
          label="Identyfikator"
          defaultValue=""
          helperText="Identyfikator sieci"
          variant="standard"
          fullWidth
          value={ this.state.nname }
          onChange={
            (event: React.ChangeEvent<HTMLInputElement>) =>
            {
              this.setState({...this.state, nname : event.target.value})  
            }              
          }
        />
        :
        <>
        <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={ this.props.redux?.name }
        label="netIdent"
        onChange={ this.selNet }
        fullWidth
        >
          { this.props.redux?.names.map(
            (name : string, index : number) => 
               <MenuItem value={name}>{name}</MenuItem>
          )}
        </Select>
        [<b>{this.props.redux?.id }</b>]</>
      }
  </Grid>
  <Grid item xs={6}>
  <FormHelperText>Zbiór z danymi do nauki sieci</FormHelperText>
    <FormControl sx={{ m: 1, minWidth: 500 }}>
        <InputLabel id="source-label">Źródło</InputLabel>
        <Select
          labelId="source-label"
          id="id_source"
          value={ this.props.redux?.source } 
          label="Źródło danych"
          onChange={ (event: SelectChangeEvent) => {
            store.dispatch(Actions.reduxNNet.setSource(event.target.value as string))
          }
         }
        >
          {this.props.redux?.sources.map(
            (src,index) => {
              return <MenuItem key={index} value={src}>
              <b>{src}</b>
            </MenuItem>
  
            }

          )}
        </Select>
        
      </FormControl>
  </Grid>
  <Grid item xs={2}>
  <InputLabel htmlFor="test_size">Parametr TEST_SIZE</InputLabel>
          <OutlinedInput
            id="test_size"
            value={this.props.redux?.test_size}
            inputProps={{ step: ".01" }}
            type="number"
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) =>
              {
                store.dispatch(Actions.reduxNNet.setTestSize(parseFloat(event.target.value)||0.0))
                //this.setState({...this.state, test_size: parseFloat(event.target.value)||0.0})  
              }              
            }
            label="TEST_SIZE"
          />
  </Grid>
  <Grid item xs={12}>
  <Typography component="legend">Warstwy:</Typography>
  <div style={{ height: 300, width: '100%' }}>
  <DataGrid rows={rows} columns={columns} checkboxSelection={false} 
        onRowClick={ this.rowClick }
        onSelectionModelChange={
        (newSelection) => {
            this.setState({...this.state, selectionModel:newSelection});
        }
      } 
  />
  </div>
  </Grid>
  <Grid item xs={12}>
    <br />
    <hr />
    <Typography variant="h5">Wybrana/nowa warstwa</Typography>
  </Grid>
  <Grid item xs={3}>
  <InputLabel htmlFor="val_cena">Nazwa warstwy</InputLabel>
          <OutlinedInput
            id="lname"
            value={this.state.lname}
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) =>
              {
                this.setState({...this.state, lname: event.target.value})  
              }              
            }      
            label="LName"
          />
  </Grid>
  <Grid item xs={3}>
  <InputLabel htmlFor="units">Ilość jednostek</InputLabel>
  <OutlinedInput
            id="units"
            value={this.state.units}
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) =>
              {
                this.setState({...this.state, units: parseInt(event.target.value)})  
              }              
            }
            label="units"
          />
  </Grid>
  <Grid item xs={3}>
  <InputLabel htmlFor="inputs">Ilość wejść (tylko pierwsza warstwa)
   </InputLabel>
   <OutlinedInput
            id="inputs"
            value={this.state.inputs}
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) =>
              {
                this.setState({...this.state, inputs: parseInt(event.target.value)})  
              }              
            }
            label="inputs"
          />
  </Grid>
  <Grid item xs={3}>
  <InputLabel htmlFor="activation">Funkcja aktywacji
   </InputLabel>

    <Select
          labelId="activation-label"
          id="id_source"
          value={ this.state.activation } 
          label="relu"
          fullWidth
          onChange={ (event: SelectChangeEvent) => {
            this.setState({...this.state, activation:  event.target.value as string}) }
          }
        >
          <MenuItem value="relu">relu</MenuItem>
          <MenuItem value="linear">linear</MenuItem>
        </Select>
  </Grid>
  <Grid item xs={12}>
  <Button variant="outlined" onClick={ ()=> this.newLevel() }>NOWA WARSTWA</Button>
  <Button variant="outlined" onClick={ ()=>this.updateLevel() }>AKTUALIZUJ WARSTWĘ</Button>
  <Button variant="contained" onClick={ ()=>{
    if (! scp.allow([scp.SCOPE_NNET_WRITE])) alert('Brak uprawnień!') 
    else this.Update()
    }
    }>ZAPISZ DEFINICJE</Button> 
  <Button variant="outlined" onClick={ ()=>this.deleteLevel() }>USUŃ WARSTWĘ</Button>

  </Grid>
</Grid>            



          </div>
          </ContainerComponent>
            
        );
    }
}

const mapStateToProps = (state : ReduxState) => ({
    redux : state.reduxNNet
  });
  
export default connect(mapStateToProps)(NNet)
  

