import { Component, ReactElement, useState } from 'react';
import { Lista, MService } from '../api'
import {
  MuiEvent, DataGrid, GridColDef, GridRowParams, GridRowId, plPL,
  //GridEvents, GridRenderCellParams, 
  GridCellParams
} from '@mui/x-data-grid';
import { Button, Grid, } from '@mui/material';

import { connect } from 'react-redux';
import { Actions, ReduxState, store } from '../rdx'
import { EModule, EstatesStore } from '../rdx/estates'
import ContainerComponent from "../mui/Container";

import React from "react";
import { useParams, useNavigate, NavigateFunction } from "react-router-dom";

import PopupMessage, { YesButton} from '../mui/PopupMessage';

import * as scp from '../scopes'


import Estate from './Estate';


type EstatesProps = {
  navigate?: NavigateFunction;
  rowClick?: (params: GridRowParams) => void;
  cellClick?: (params: GridCellParams) => void
  redux?: EstatesStore
}

type EstatesState = {
  rev: number;
  selId: number;
  delId: number;
  selectionModel: GridRowId[];
  isShown : boolean;
  toggle : (oid : number) => void
}

class Estates extends Component<EstatesProps, EstatesState> {

  constructor(props: EstatesProps) {
    super(props);
    const toggle = (oid:number) => { this.setState({...this.state, delId : oid, isShown : !this.state.isShown}) };
    this.state = {
      selectionModel: [],
      selId: 0,
      delId: 0,
      rev: 0,
      isShown : false, 
      toggle : toggle
    }
  }

  handleCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    if (params.field !== 'Operacje') {
      this.setState({ ...this.state, selId: params.row.id /*.toString()*/ })
      if (this.props.cellClick) this.props.cellClick(params)
      else {
        if (!this.props.redux?.obiekt) {
          //           store.dispatch(Actions.reduxEstates.setOid(params.row.id))  
          //            this.getEstates()           
        }
      }
    }
    event.stopPropagation();
  };

  handleRowClick = (params: GridRowParams) => {
    if (this.props.rowClick) this.props.rowClick(params)
    //(event : GridEvents.rowClick) => {
    // alert(JSON.stringify( params.id ))
    //event.stopPropagation();
  };


  rowClick = (params: GridRowParams) => {
    if (this.props.rowClick) this.props.rowClick(params)
  }


  async getEstates() {
    MService.getObiektLista(250, 1, '', {})
      .then(
        (result) => {
          store.dispatch(Actions.reduxEstates.setLista(EModule.estates, result))
        }
      ).catch((err: any) => {
        console.log('Error ' + JSON.stringify(err));
        store.dispatch(Actions.reduxEstates.setLista(EModule.estates, 
          new Lista({klucz:'',strona:1,obiekty:[]})))
        alert('Błąd - zaloguj się ponownie ' );

      }
      )
  }

  async getUsun(id: number) {
    if (this.props.redux && (id >= 0)) {
      let o = this.props.redux.lista?.obiekty.find(
        (obj) => {
          return obj.id === id;
        }
      );
      if (o) {
        MService.getUsun(id, {})
          .then(
            (result) => {
              if (this.props.redux?.lista) {
                let newList = this.props.redux.lista?.obiekty.filter(o => o.id !== id);
                store.dispatch(Actions.reduxEstates.setLista(EModule.estates, 
                  {
                    strona: this.props.redux.lista.strona,
                    klucz: this.props.redux.lista.klucz,
                    obiekty: newList
                  }
                ))  
              }
            }
          ).catch((err: any) => {
            console.log('Error ' + JSON.stringify(err));
            alert('Error ' + JSON.stringify(err));
          }
          )
      }
    }
  }

  async SzacujMakro2() {
    /*
    // z powodu problemów z zaobami serwera - szacowanie wszystkich jest offline
    // tu tylko wyliczenie atrybutu....
    MService.SzacujMakro({})
        .then(
            (result) => {
               this.getEstates()
            }
        ).catch((err: any) => {
            console.log('Error ' + JSON.stringify(err));
            alert('Error ' + JSON.stringify(err));
          }
        )
        */
  }


  async getSzacuj(id: number) {
    if (this.props.redux && (id >= 0)) {
      let o = this.props.redux.lista?.obiekty.find(
        (obj) => {
          return obj.id === id;
        }
      );
      if (o) {
        let p = o.powierzchnia; //parseFloat(o.powierzchnia)

        MService.getSzacuj(false, o.ocena_lokalizacja, o.ocena_otoczenie,
          o.ocena_stan_tech, o.ocena_wykonczenie,
          o.ocena_usytuowanie, o.ocena_zmiana, o.ocena_media, o.ocena_inne,
          p,''
          , {})
          .then(
            (result) => {
              //this.setState({ obiekty : result.obiekty });       }
              alert('Szacunkowa wartosć: ' + result.opis)

              MService.zapiszSzacunek(id, result.opis, {}).then(
                (result) => {
                  this.getEstates();
                }
              )
            }
          ).catch((err: any) => {
            console.log('Error ' + JSON.stringify(err));
            alert('Error ' + JSON.stringify(err));
          }
          )
      }
    }
  }

  


  componentDidMount() {
    store.dispatch(Actions.reduxEstates.setOid(0))
    if (store.getState().reduxLogin.login) this.getEstates()
  }

  

  render() {
    if (!store.getState().reduxLogin.login) 
      return(<ContainerComponent>
        <h3>Nieruchomości</h3>
        <div style={{color:'red'}}>Musisz być zalogowany!</div>
        </ContainerComponent>
      )
/*    if (this.state.delId>0) {
      alert(this.state.delId)
      this.setState({...this.state, delId:0})
    }
  */  
    if (this.props.redux?.module !== EModule.estates) {
      //        this.setState({...this.state,selId:''})
      //        store.dispatch(Actions.reduxEstates.setLista(EModule.estates, new Lista({klucz:'',strona:'',obiekty:[]})))
      //this.getEstates();
    }

    let rows = [];
    if (this.props.redux && this.props.redux.lista)
      for (let o of this.props.redux.lista.obiekty) {
        rows.push(
          {
            id: o.id,
            obiekt: o.obiekt,
            lokalizacja: o.lokalizacja,
            adres: o.adres,
            przeznaczenie: o.przeznaczenie,
            opis: o.opis,
            cena: o.cena,
            dochod: o.dochod,
            ocena_lokalizacja: o.ocena_lokalizacja,
            szacowanie: o.szacowanie,
            kiedy: o.kiedy,
            kategoria: o.kategoria,
            m2: o.powierzchnia,

          },
        )

      }
    const columns: GridColDef[] = [
      { field: 'id', headerName: 'id', width: 50 },
      { field: 'obiekt', headerName: 'Obiekt', width: 150 },
      { field: 'lokalizacja', headerName: 'Lokalizacja', width: 180 },
      { field: 'przeznaczenie', headerName: 'Przeznaczenie', width: 150 },
      { field: 'szacowanie', headerName: 'Ostatnie szac.', width: 150 },
      { field: 'kiedy', headerName: 'Kiedy wykonano', width: 150 },
      {
        field: "Operacje",
        width: 150,
        renderCell: (cellValues) => {
          return (
            <>

              <Button
                variant="outlined" size="small"
                color="info"
                onClick={(event) => {
                  //this.getSzacuj(cellValues.row.id);
                  if (this.props.navigate)
                    this.props.navigate('/ai/jcmodel2/' + cellValues.row.id.toString())
                }}
              >
                szacuj
              </Button>

              <Button
                variant="outlined" size="small"
                color="warning"
                onClick={(event) => {
                  if (! scp.allow([scp.SCOPE_ESTATES_DELETE])) alert('Brak uprawnień!') 
                  else this.state.toggle(cellValues.row.id);
                    //this.getUsun(cellValues.row.id);
                }}
              >
                usuń
              </Button>

            </>
          );
        }
      },
      { field: 'kategoria', headerName: 'Kategoria', width: 150 },
      { field: 'opis', headerName: 'Opis', width: 250 },
      { field: 'm2', headerName: 'pow.', width: 100 },
      { field: 'dochod', headerName: 'dochód jedn.', width: 100 },
      { field: 'adres', headerName: 'Adres', width: 200 },
    ];

    return (
      <ContainerComponent>
        <Grid container spacing={2}>
        <Grid item xs={6}>
        <h3>Nieruchomości</h3>
        </Grid>
        <Grid item xs={6}>
          {/*<Button
               variant="outlined" size="small"
               color="info"
               onClick={(event) => {     
                          this.SzacujMakro2();
               }}
          >
            oblicz wpływ czynników makroekonomicznych
          </Button>
          */}
        </Grid>
        </Grid>


        <PopupMessage
        isShown={this.state.isShown}
        hide={ ()=>this.state.toggle(0) }
        headerText="OSTRZEŻENIE"
        modalContent={
          <div>
            <span style={{ color: "red", fontSize:"1.1em", fontWeight:"bold" }} > 
            Na pewno chcesz usunąć tą nieruchomość z ewidencji? Jeśli tak - kliknij przycisk "USUŃ".
            </span>
            <br />
            <hr />
            
            <Button onClick={ ()=>{ 
                this.getUsun(this.state.delId)                 
                this.state.toggle(0); 
               } 
              }>USUŃ</Button>
           <hr />
          </div>
        }
      />

        <div style={{ height: '400px', width: '100%' }}>
          <DataGrid rows={rows} columns={columns} checkboxSelection={false}
            onCellClick={this.handleCellClick}
            localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
        {
          (this.state.selId) ?
            <div style={{ width: '100%' }}>

              <Estate oid={this.state.selId} />
            </div> : <></>
        }
      </ContainerComponent>

    );
  }

}


const FEstates = (props: EstatesProps) => {
  let navigate = useNavigate();

  return (
    <Estates navigate={navigate} {...props} />
  );
}

const mapStateToProps = (state: ReduxState) => ({
  redux: state.reduxEstates
})


export default connect(mapStateToProps)(FEstates)

