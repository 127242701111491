import { Component } from 'react';
import {
  Button, Grid, InputLabel, MenuItem, OutlinedInput,
  Select, SelectChangeEvent, TextField, Typography
} from '@mui/material';

import { DataGrid, plPL, GridColDef, GridRowParams, GridRowId } from '@mui/x-data-grid';
import { Actions, ReduxState, store } from '../rdx'
import { connect } from 'react-redux';
import { NNetStore  } from '../rdx/nnet'
import { baseUrl0, NNetService, NNetTrain } from '../api';

import ContainerComponent from "../mui/Container";


import * as scp from '../scopes'

type NTrainingProps = {
  redux? : NNetStore;

}

type NTrainingState = {
  name: string;
  epochs: number;
  selected? : NNetTrain;
  imgUrl : string;
/*
  ident: string;
  names: string[];
  numHiddenLevels: number;
  units:number;
  test_size : number;
  source: string;
  result: NTResult
*/
}


class NTraining extends Component<NTrainingProps, NTrainingState> {

  constructor(props: NTrainingProps) {
    super(props);
    this.state = {
      name: '',
      epochs: 2500,
      imgUrl:''
/*
      ident: 'model1',
      names: ['sieć 1', 'sieć 2'],
      numHiddenLevels: 1, 
      units:22,
      test_size : 0.10,
      source: "0",
      result: {
        loss: 0,
        mse: 0,
        img: ''
      }
      */
    }
  }

  async getNames()
  {
    NNetService.getNNetLista({})
    .then(
        (result ) => {  
          store.dispatch(Actions.reduxNNet.setNames(result.nets))
          this.getNNet( this.props.redux?.name || '' )
        }
     ).catch((err: any) => {
         console.log('Error ' + JSON.stringify(err));
         alert('Error ' + JSON.stringify(err));
       }
   ) 
  }


  async getTrains() {  
    NNetService.getTrains({})
      .then(
        (result) => {
          store.dispatch(Actions.reduxNNet.setTrains(result.trains))
        }
      ).catch((err: any) => {
        console.log('Error ' + JSON.stringify(err));
        alert('Error ' + JSON.stringify(err));
      }
      )

  }


  async newOrder() {

    
    NNetService.postNewTrain(this.state.name, this.state.epochs, {})
      .then(
        (result) => {
          this.getTrains();
        }
      ).catch((err: any) => {
        console.log('Error ' + JSON.stringify(err));
        alert('Error ' + JSON.stringify(err));
      }
      )

  }

  getLoad = () => {  
    alert('Brak dostępu')
  }

  rowClick = (params: GridRowParams) => {  
    if (typeof(params.id)=='number') {
      let sel=this.props.redux?.trains?.find(o => o.id==params.id)
      if (sel) {
        const path=sel.path ||'/'
        const id = sel.id
        let imgUrl = `${baseUrl0}/file/${path}/${id}.png`
        this.setState({...this.state, selected: sel, imgUrl : imgUrl})     
      }      
    }
  }


  async getNNet(name: string) {
    this.setState({ ...this.state, name: name })

  }



  selNet = (event: SelectChangeEvent) => {
    this.getNNet(event.target.value as string)
  }

  componentDidMount() {
    if (scp.allow([scp.SCOPE_NNET_TRAIN])) {
      this.getTrains();
      this.getNames() 
    }
  }


  render() {

    if (!scp.allow([scp.SCOPE_NNET_TRAIN])) return(<ContainerComponent>Brak dostępu</ContainerComponent>)

    let rows = [];
    if (this.props.redux && this.props.redux.trains) {
      for (let tr of this.props.redux.trains)
        rows.push(
            { id: tr.id, 
              nneuron_id : tr.nneuron_id,
              epochs : tr.epochs,
              test_size : tr.test_size,
              order : tr.order,
              done : tr.done,
              loss : tr.loss,
              mse : tr.mse,
              metrics : tr.metrics,
              path  :  tr.path            },
        )
    }
    const columns: GridColDef[] = [
            { field: 'id', headerName: 'id', width: 75 },
            { field: 'nneuron_id', headerName: 'sieć', width: 75 },
            { field: 'order', headerName: 'kiedy zlecono', width: 250 },
            { field: 'done', headerName: 'czy / kiedy wykonano', width:250 },
            { field: 'epochs', headerName: 'Epoki', width: 150 },
            { field: 'mse', headerName: 'Błąd średn.', width: 150 },
            { field: 'loss', headerName: 'Strata', width: 150,  type: 'number', },
            { field: 'test_size', headerName: 'test_size', width: 100,  type: 'number' },
        ];



    return (
      <ContainerComponent>
        <div style={{ width: '100%' }}>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Trenowanie sieci</Typography>
              <div style={{ height: '400px', width: '100%' }}>
          <DataGrid rows={rows} columns={columns} checkboxSelection={false}
             onRowClick={ this.rowClick }
            localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>
        {
           
        }
            </Grid>
            <Grid item xs={4}>
              <InputLabel htmlFor="nnet">Wybór sieci (nowe zlecenie)</InputLabel>
              <Select
                labelId="tr-nnet-select-helper-label"
                id="tr-nnet-select-helper"
                value={ this.state.name }
                label="netIdent"
                onChange={this.selNet}
                fullWidth
              >
              { this.props.redux?.names.map(
                (name : string, index : number) => 
                  <MenuItem key={index} value={name}>{name}</MenuItem>
              )}
              </Select>
            </Grid>
            <Grid item xs={4}>
              <InputLabel htmlFor="epochs">Ile epok?</InputLabel>
              <OutlinedInput
                id="epochs"
                value={this.state.epochs}
                inputProps={{ step: "100" }}
                type="number"
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ ...this.state, epochs: parseFloat(event.target.value) || 0 })
                  }
                }
                label="Epoki"
              />
            </Grid>
            <Grid item xs={4}>
              {
                /*
              <InputLabel htmlFor="epochs">Ile warstw głębokich?</InputLabel>
              <OutlinedInput
                id="epochs"
                value={this.state.numHiddenLevels}
                inputProps={{ step: "1" }}
                type="number"
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ ...this.state, numHiddenLevels: parseFloat(event.target.value) || 1 })
                  }
                }
                label="Warstwy"
              />
              <InputLabel htmlFor="epochs">Ile elementów w warstwie?</InputLabel>
              <OutlinedInput
                id="epochs"
                value={this.state.units}
                inputProps={{ step: "1" }}
                type="number"
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ ...this.state, units: parseFloat(event.target.value) || 0 })
                  }
                }
                label="Elementy"
              />
                */
              }
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={() => this.newOrder()}>TRENUJ - NOWE ZLECENIE</Button>
              <Button variant="outlined" onClick={() => this.getLoad()}>ZAŁADUJ</Button>
            </Grid>
            <Grid item xs={12}>
              <br />
              <hr />
              <Typography variant="h5">Wyniki</Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="val_cena">Parametry sieci po treningu</InputLabel>
              {
                (this.state.selected) ?
                  <div>
                    <ul>
                      <li>Strata (LOSS) = <b>{this.state.selected.loss}</b></li>
                      <li>Błąd średniokwadratowy (MSE) = <b>{this.state.selected.mse}</b></li>
                    </ul>
                    <img src={ this.state.imgUrl} alt='History' />
                  </div>
                  : <></>
                  
              }

            </Grid>
          </Grid>


        </div>
      </ContainerComponent>

    );
  }
}

const mapStateToProps = (state : ReduxState) => ({
  redux : state.reduxNNet
});

export default connect(mapStateToProps)(NTraining)


