import * as React from "react"
import { Button, TextField, Typography } from '@mui/material';

import { LoginInfo, UserService } from './api'
import { ReduxState, Actions, store } from './rdx'
//import { LoginStore } from './rdx/login'

import { connect } from 'react-redux';

type ParState = {
  ok: boolean,
  message: string,
}
type ParProps = {
  redux? : any
}

class Login extends React.Component<ParProps, ParState>  {

  v_username: string | any;
  v_password: string | any;

  change_username = (e: React.FormEvent) => {
    let target = e.target as HTMLInputElement;
    this.v_username = target.value;
  }
  change_password = (e: React.FormEvent) => {
    let target = e.target as HTMLInputElement;
    this.v_password = target.value;
  }

  constructor(props: any) {
    super(props, {});
    this.state = {
      ok: false,
      message: ''
    };
  }

  async apiLogin(p_username: string, p_password: string) {
    UserService.postLogin({username:p_username,password:p_password},{} ).then(
      (ret: LoginInfo) => {
/*        this.setState({
          ...this.state,
          session: ret
        })
        */
       if (ret.retcode===0) {
         store.dispatch(Actions.reduxLogin.setLoginX(p_username,ret.token,
          ret.scopes, ret.message));
//         store.dispatch(Actions.auth.setSession(ret.session));
    }
       else
         alert('Błąd logowania');
      }
    ).catch((err: any) => {
      alert('API Error: ' + p_username+ ' == '+
               p_password+' == '+JSON.stringify(err));
    }
    );
  }

  render = () => {
/*    if (this.props.redux.login)  {
      return <Redirect
         to={{
          pathname: '/reservations',
          state: { from: '/login' }
        }}
      />     
    }
    */
    let btnSetPasswordClick = () => {
      this.apiLogin(this.v_username, this.v_password)
    }
    if (this.props.redux.login) {
     return (<><div id="session" style={{display: 'none'}}>{this.props.redux.token}</div>
     <div>Jesteś zalogowany(a) jako: <b>{this.props.redux.login}</b></div>
     <div>
      {this.props.redux.scopesTxt? <><br />Uprawnienia:<br />{this.props.redux.scopesTxt}</>:<></>}
     </div>
     </>)
    }
    return (
      <form id="passwordext-form" name="passwordext-form"
        method="post" action="">
        <div id="session"  style={{display: 'none'}}>{this.props.redux.login}</div>
        <TextField
          onChange={(x: React.FormEvent) => this.change_username(x)}
          label="username"
        />
        <br /> <br />
        <TextField
          onChange={(e: React.FormEvent) => this.change_password(e)}
          label="password"
          type="password" />
        <br />
        <span style={{ color: 'red' }}>
          {this.state.message}
        </span>
        <br />
        <Button variant="contained" color="primary"
          onClick={btnSetPasswordClick}>
          Login
        </Button>
      </form>
    )
  }
}

const mapStateToProps = (state : ReduxState) => ({
  redux : state.reduxLogin
});

export default connect(mapStateToProps)(Login);
